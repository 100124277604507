<template>
  <div class="body">
    <app-header />
    <div style="margin-left: 15px; margin-right: 15px">
      <h1>Google TypeScript Style Guide</h1>
      <div id="tocDiv" class="vertical_toc col-md-2">
        <div class="toc_title"></div>
        <h3 id="toc" class="ignoreLink">Table of Contents</h3>
        <table class="columns">
          <tbody valign="top" class="toc">
            <tr>
              <td class="two_columns">
                <dl>
                  <dt><a href="#syntax">Syntax</a></dt>
                  <dd><a href="#identifiers">Identifiers</a></dd>
                  <dd>
                    <a href="#file-encoding-utf-8">File encoding: UTF-8</a>
                  </dd>
                  <dd>
                    <a href="#comments-documentation"
                      >Comments &amp; Documentation</a
                    >
                  </dd>
                </dl>
                <dl>
                  <dt><a href="#language-rules">Language Rules</a></dt>
                  <dd><a href="#visibility">Visibility</a></dd>
                  <dd><a href="#constructors">Constructors</a></dd>
                  <dd><a href="#class-members">Class Members</a></dd>
                  <dd>
                    <a href="#primitive-types-wrapper-classes"
                      >Primitive Types &amp; Wrapper Classes</a
                    >
                  </dd>
                  <dd><a href="#array-constructor">Array constructor</a></dd>
                  <dd><a href="#type-coercion">Type coercion</a></dd>
                  <dd><a href="#variables">Variables</a></dd>
                  <dd><a href="#exceptions">Exceptions</a></dd>
                  <dd><a href="#iterating-objects">Iterating objects</a></dd>
                  <dd>
                    <a href="#iterating-containers">Iterating containers</a>
                  </dd>
                  <dd>
                    <a href="#using-the-spread-operator"
                      >Using the spread operator</a
                    >
                  </dd>
                  <dd>
                    <a href="#control-flow-statements-blocks"
                      >Control flow statements &amp; blocks</a
                    >
                  </dd>
                  <dd><a href="#switch-statements">Switch Statements</a></dd>
                  <dd><a href="#equality-checks">Equality Checks</a></dd>
                  <dd>
                    <a href="#function-declarations">Function Declarations</a>
                  </dd>
                  <dd>
                    <a href="#function-expressions">Function Expressions</a>
                  </dd>
                  <dd>
                    <a href="#automatic-semicolon-insertion"
                      >Automatic Semicolon Insertion</a
                    >
                  </dd>
                  <dd><a href="#ts-ignore">@ts-ignore</a></dd>
                  <dd>
                    <a href="#type-and-non-nullability-assertions"
                      >Type and Non-nullability Assertions</a
                    >
                  </dd>
                  <dd>
                    <a href="#member-property-declarations"
                      >Member property declarations</a
                    >
                  </dd>
                  <dd><a href="#enums">Enums</a></dd>
                  <dd>
                    <a href="#debugger-statements">Debugger statements</a>
                  </dd>
                  <dd><a href="#decorators">Decorators</a></dd>
                </dl>
                <dl>
                  <dt>
                    <a href="#source-organization">Source Organization</a>
                  </dt>
                  <dd><a href="#modules">Modules</a></dd>
                  <dd><a href="#exports">Exports</a></dd>
                  <dd><a href="#imports">Imports</a></dd>
                  <dd>
                    <a href="#organize-by-feature">Organize By Feature</a>
                  </dd>
                </dl>
                <dl>
                  <dt><a href="#type-system">Type System</a></dt>
                  <dd><a href="#type-inference">Type Inference</a></dd>
                  <dd><a href="#null-vs-undefined">Null vs Undefined</a></dd>
                  <dd>
                    <a href="#structural-types-vs-nominal-types"
                      >Structural Types vs Nominal Types</a
                    >
                  </dd>
                  <dd>
                    <a href="#interfaces-vs-type-aliases"
                      >Interfaces vs Type Aliases</a
                    >
                  </dd>
                  <dd><a href="#arrayt-type">Array&lt;T&gt; Type</a></dd>
                  <dd>
                    <a href="#indexable-key-string-number-type"
                      >Indexable ({[key: string]: number}) Type</a
                    >
                  </dd>
                  <dd>
                    <a href="#mapped-conditional-types"
                      >Mapped &amp; Conditional Types</a
                    >
                  </dd>
                  <dd><a href="#any">any Type</a></dd>
                  <dd><a href="#tuple-types">Tuple Types</a></dd>
                  <dd><a href="#wrapper-types">Wrapper types</a></dd>
                  <dd>
                    <a href="#return-type-only-generics"
                      >Return type only generics</a
                    >
                  </dd>
                </dl>
                <dl>
                  <dt><a href="#consistency">Consistency</a></dt>
                  <dd><a href="#goals">Goals</a></dd>
                </dl>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-10">
        <h1>TypeScript style guide</h1>

        <section>
          This is the external guide that's based on the internal Google version
          but has been adjusted for the broader audience. There is no automatic
          deployment process for this version as it's pushed on-demand by
          volunteers.

          <p>
            It contains both rules and best practices. Choose those that work
            best for your team.
          </p>
        </section>

        <p>
          This Style Guide uses
          <a href="http://tools.ietf.org/html/rfc2119">RFC 2119</a> terminology
          when using the phrases <em>must</em>, <em>must not</em>,
          <em>should</em>, <em>should not</em>, and <em>may</em>. All examples
          given are non-normative and serve only to illustrate the normative
          language of the style guide.
        </p>

        <h2 id="syntax" style="left: -46px; position: relative">
          Syntax<a href="#syntax" alt="link to syntax"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h2>

        <h3 id="identifiers" style="left: -46px; position: relative">
          Identifiers<a href="#identifiers" alt="link to identifiers"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Identifiers must use only ASCII letters, digits, underscores (for
          constants and structured test method names), and the '\(' sign. Thus
          each valid identifier name is matched by the regular expression
          `[\)\w]+`.
        </p>

        <table>
          <thead>
            <tr>
              <th>Style</th>
              <th>Category</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td><code>UpperCamelCase</code></td>
              <td>
                class / interface / type / enum / decorator / type parameters
              </td>
            </tr>
            <tr>
              <td><code>lowerCamelCase</code></td>
              <td>
                variable / parameter / function / method / property / module
                alias
              </td>
            </tr>
            <tr>
              <td><code>CONSTANT_CASE</code></td>
              <td>global constant values, including enum values</td>
            </tr>
            <tr>
              <td><code>#ident</code></td>
              <td>private identifiers are never used.</td>
            </tr>
          </tbody>
        </table>

        <ul>
          <li>
            <p class="abbreviations">
              <strong>Abbreviations</strong>: Treat abbreviations like acronyms
              in names as whole words, i.e. use <code>loadHttpUrl</code>, not
              <del><code>loadHTTPURL</code></del
              >, unless required by a platform name (e.g.
              <code>XMLHttpRequest</code>).
            </p>
          </li>
          <li>
            <p>
              <strong>Dollar sign</strong>: Identifiers
              <em>should not</em> generally use <code>$</code>, except when
              aligning with naming conventions for third party frameworks.
              <a href="#naming-style">See below</a> for more on using
              <code>$</code> with <code>Observable</code> values.
            </p>
          </li>
          <li>
            <p>
              <strong>Type parameters</strong>: Type parameters, like in
              <code>Array&lt;T&gt;</code>, may use a single upper case character
              (<code>T</code>) or <code>UpperCamelCase</code>.
            </p>
          </li>
          <li>
            <p>
              <strong>Test names</strong>: Test method names in Closure
              <code>testSuite</code>s and similar xUnit-style test frameworks
              may be structured with <code>_</code> separators, e.g.
              <code>testX_whenY_doesZ()</code>.
            </p>
          </li>
          <li>
            <p>
              <strong><code>_</code> prefix/suffix</strong>: Identifiers must
              not use <code>_</code> as a prefix or suffix.
            </p>

            <p>
              This also means that <code>_</code> must not be used as an
              identifier by itself (e.g. to indicate a parameter is unused).
            </p>
          </li>
        </ul>

        <blockquote>
          <p class="tip">
            Tip: If you only need some of the elements from an array (or
            TypeScript tuple), you can insert extra commas in a destructuring
            statement to ignore in-between elements:
          </p>

          <pre
            class="language-ts prettyprint prettyprinted"
            style=""
          ><span class="kwd">const</span><span class="pln"> </span><span class="pun">[</span><span class="pln">a</span><span class="pun">,</span><span class="pln"> </span><span class="pun">,</span><span class="pln"> b</span><span class="pun">]</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[</span><span class="lit">1</span><span class="pun">,</span><span class="pln"> </span><span class="lit">5</span><span class="pun">,</span><span class="pln"> </span><span class="lit">10</span><span class="pun">];</span><span class="pln">  </span><span class="com">// a &lt;- 1, b &lt;- 10</span></pre>
        </blockquote>

        <ul>
          <li>
            <p class="imports">
              <strong>Imports</strong>: Module namespace imports are
              <code>lowerCamelCase</code> while files are
              <code>snake_case</code>, which means that imports correctly will
              not match in casing style, such as
            </p>

            <pre
              class="language-ts good prettyprint prettyprinted"
              style=""
            ><span class="kwd">import</span><span class="pln"> </span><span class="pun">*</span><span class="pln"> as fooBar </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./foo_bar'</span><span class="pun">;</span></pre>

            <p>
              Some libraries might commonly use a namespace import prefix that
              violates this naming scheme, but overbearingly common open source
              use makes the violating style more readable. The only libraries
              that currently fall under this exception are:
            </p>

            <ul>
              <li>
                <a href="https://jquery.com/">jquery</a>, using the
                <code>$</code> prefix
              </li>
              <li>
                <a href="https://threejs.org/">threejs</a>, using the
                <code>THREE</code> prefix
              </li>
            </ul>
          </li>
          <li>
            <p class="constants">
              <strong>Constants</strong>: <code>CONSTANT_CASE</code> indicates
              that a value is <em>intended</em> to not be changed, and may be
              used for values that can technically be modified (i.e. values that
              are not deeply frozen) to indicate to users that they must not be
              modified.
            </p>

            <pre
              class="language-ts good prettyprint prettyprinted"
              style=""
            ><span class="kwd">const</span><span class="pln"> UNIT_SUFFIXES </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="str">'milliseconds'</span><span class="pun">:</span><span class="pln"> </span><span class="str">'ms'</span><span class="pun">,</span><span class="pln">
  </span><span class="str">'seconds'</span><span class="pun">:</span><span class="pln"> </span><span class="str">'s'</span><span class="pun">,</span><span class="pln">
</span><span class="pun">};</span><span class="pln">
</span><span class="com">// Even though per the rules of JavaScript UNIT_SUFFIXES is</span><span class="pln">
</span><span class="com">// mutable, the uppercase shows users to not modify it.</span></pre>

            <p>
              A constant can also be a <code>static readonly</code> property of
              a class.
            </p>

            <pre
              class="language-ts good prettyprint prettyprinted"
              style=""
            ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> </span><span class="kwd">static</span><span class="pln"> readonly MY_SPECIAL_NUMBER </span><span class="pun">=</span><span class="pln"> </span><span class="lit">5</span><span class="pun">;</span><span class="pln">

  bar</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">return</span><span class="pln"> </span><span class="lit">2</span><span class="pln"> </span><span class="pun">*</span><span class="pln"> </span><span class="typ">Foo</span><span class="pun">.</span><span class="pln">MY_SPECIAL_NUMBER</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

            <p>
              If a value can be instantiated more than once over the lifetime of
              the program, or if users mutate it in any way, it must use
              <code>lowerCamelCase</code>.
            </p>

            <p>
              If a value is an arrow function that implements an interface, then
              it can be declared <code>lowerCamelCase</code>.
            </p>
          </li>
          <li></li>
        </ul>

        <h4 id="aliases" style="left: -46px; position: relative">
          Aliases<a href="#aliases" alt="link to aliases"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          When creating a local-scope alias of an existing symbol, use the
          format of the existing identifier. The local alias must match the
          existing naming and format of the source. For variables use
          <code>const</code> for your local aliases, and for class fields use
          the <code>readonly</code> attribute.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="pln">
</span><span class="kwd">const</span><span class="pln"> </span><span class="pun">{</span><span class="typ">Foo</span><span class="pun">}</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="typ">SomeType</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> CAPACITY </span><span class="pun">=</span><span class="pln"> </span><span class="lit">5</span><span class="pun">;</span><span class="pln">

</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">Teapot</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  readonly </span><span class="typ">BrewStateEnum</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="typ">BrewStateEnum</span><span class="pun">;</span><span class="pln">
  readonly CAPACITY </span><span class="pun">=</span><span class="pln"> CAPACITY</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h4 id="naming-style" style="left: -46px; position: relative">
          Naming style<a href="#naming-style" alt="link to naming-style"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          TypeScript expresses information in types, so names
          <em>should not</em> be decorated with information that is included in
          the type.
        </p>

        <p>Some concrete examples of this rule:</p>

        <ul>
          <li>
            Do not use trailing or leading underscores for private properties or
            methods.
          </li>
          <li>
            Do not use the <code>opt_</code> prefix for optional parameters.
            <ul>
              <li>
                For accessors, see
                <a href="#getters-and-setters-accessors">accessor rules</a>
                below.
              </li>
            </ul>
          </li>
          <li>
            Do not mark interfaces specially (<del
              ><code>IMyInterface</code></del
            >
            or <del><code>MyFooInterface</code></del
            >) unless it's idiomatic in its environment. When introducing an
            interface for a class, give it a name that expresses why the
            interface exists in the first place (e.g.
            <code>class TodoItem</code> and
            <code>interface TodoItemStorage</code> if the interface expresses
            the format used for storage/serialization in JSON).
          </li>
          <li>
            Suffixing <code>Observable</code>s with <code>$</code> is a common
            external convention and can help resolve confusion regarding
            observable values vs concrete values. Judgement on whether this is a
            useful convention is left up to individual teams, but
            <em>should</em> be consistent within projects.
          </li>
        </ul>

        <h4 id="descriptive-names" style="left: -46px; position: relative">
          Descriptive names<a
            href="#descriptive-names"
            alt="link to descriptive-names"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Names <em>must</em> be descriptive and clear to a new reader. Do not
          use abbreviations that are ambiguous or unfamiliar to readers outside
          your project, and do not abbreviate by deleting letters within a word.
        </p>

        <ul>
          <li>
            <strong>Exception</strong>: Variables that are in scope for 10 lines
            or fewer, including arguments that are <em>not</em> part of an
            exported API, <em>may</em> use short (e.g. single letter) variable
            names.
          </li>
        </ul>

        <h3 id="file-encoding-utf-8" style="left: -46px; position: relative">
          File encoding: UTF-8<a
            href="#file-encoding-utf-8"
            alt="link to file-encoding-utf-8"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          For non-ASCII characters, use the actual Unicode character (e.g.
          <code>∞</code>). For non-printable characters, the equivalent hex or
          Unicode escapes (e.g. <code>\u221e</code>) can be used along with an
          explanatory comment.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Perfectly clear, even without a comment.</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> units </span><span class="pun">=</span><span class="pln"> </span><span class="str">'μs'</span><span class="pun">;</span><span class="pln">

</span><span class="com">// Use escapes for non-printable characters.</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> output </span><span class="pun">=</span><span class="pln"> </span><span class="str">'\ufeff'</span><span class="pln"> </span><span class="pun">+</span><span class="pln"> content</span><span class="pun">;</span><span class="pln">  </span><span class="com">// byte order mark</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Hard to read and prone to mistakes, even with the comment.</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> units </span><span class="pun">=</span><span class="pln"> </span><span class="str">'\u03bcs'</span><span class="pun">;</span><span class="pln"> </span><span class="com">// Greek letter mu, 's'</span><span class="pln">

</span><span class="com">// The reader has no idea what this is.</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> output </span><span class="pun">=</span><span class="pln"> </span><span class="str">'\ufeff'</span><span class="pln"> </span><span class="pun">+</span><span class="pln"> content</span><span class="pun">;</span></pre>

        <h3 id="comments-documentation" style="left: -46px; position: relative">
          Comments &amp; Documentation<a
            href="#comments-documentation"
            alt="link to comments-documentation"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <h4 id="jsdoc-vs-comments" style="left: -46px; position: relative">
          JSDoc vs comments<a
            href="#jsdoc-vs-comments"
            alt="link to jsdoc-vs-comments"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          There are two types of comments, JSDoc (<code>/** ... */</code>) and
          non-JSDoc ordinary comments (<code>// ...</code> or
          <code>/* ... */</code>).
        </p>

        <ul>
          <li>
            Use <code>/** JSDoc */</code> comments for documentation, i.e.
            comments a user of the code should read.
          </li>
          <li>
            Use <code>// line comments</code> for implementation comments, i.e.
            comments that only concern the implementation of the code itself.
          </li>
        </ul>

        <p>
          JSDoc comments are understood by tools (such as editors and
          documentation generators), while ordinary comments are only for other
          humans.
        </p>

        <h4
          id="jsdoc-rules-follow-the-javascript-style"
          style="left: -46px; position: relative"
        >
          JSDoc rules follow the JavaScript style<a
            href="#jsdoc-rules-follow-the-javascript-style"
            alt="link to jsdoc-rules-follow-the-javascript-style"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          In general, follow the

          <a href="https://google.github.io/styleguide/jsguide.html#jsdoc"
            >JavaScript style guide's rules for JSDoc</a
          >, sections 7.1 - 7.5. The remainder of this section describes
          exceptions to those rules.
        </p>

        <h4
          id="document-all-top-level-exports-of-modules"
          style="left: -46px; position: relative"
        >
          Document all top-level exports of modules<a
            href="#document-all-top-level-exports-of-modules"
            alt="link to document-all-top-level-exports-of-modules"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Use <code>/** JSDoc */</code> comments to communicate information to
          the users of your code. Avoid merely restating the property or
          parameter name. You <em>should</em> also document all properties and
          methods (exported/public or not) whose purpose is not immediately
          obvious from their name, as judged by your reviewer.
        </p>

        <p class="exception">
          Exception: Symbols that are only exported to be consumed by tooling,
          such as @NgModule classes, do not require comments.
        </p>

        <h4
          id="omit-comments-that-are-redundant-with-typescript"
          style="left: -46px; position: relative"
        >
          Omit comments that are redundant with TypeScript<a
            href="#omit-comments-that-are-redundant-with-typescript"
            alt="link to omit-comments-that-are-redundant-with-typescript"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          For example, do not declare types in <code>@param</code> or
          <code>@return</code> blocks, do not write <code>@implements</code>,
          <code>@enum</code>, <code>@private</code> etc. on code that uses the
          <code>implements</code>, <code>enum</code>, <code>private</code> etc.
          keywords.
        </p>

        <h4 id="do-not-use-override" style="left: -46px; position: relative">
          Do not use <code>@override</code
          ><a href="#do-not-use-override" alt="link to do-not-use-override"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>Do not use <code>@override</code> in TypeScript source code.</p>

        <p>
          <code>@override</code> is not enforced by the compiler, which is
          surprising and leads to annotations and implementation going out of
          sync. Including it purely for documentation purposes is confusing.
        </p>

        <h4 id="redundant-comments" style="left: -46px; position: relative">
          Make comments that actually add information<a
            href="#redundant-comments"
            alt="link to redundant-comments"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          For non-exported symbols, sometimes the name and type of the function
          or parameter is enough. Code will <em>usually</em> benefit from more
          documentation than just variable names though!
        </p>

        <ul>
          <li>
            <p>
              Avoid comments that just restate the parameter name and type, e.g.
            </p>

            <pre
              class="language-ts bad prettyprint prettyprinted"
              style=""
            ><span class="com">/** @param fooBarService The Bar service for the Foo application. */</span></pre>
          </li>
          <li>
            <p>
              Because of this rule, <code>@param</code> and
              <code>@return</code> lines are only required when they add
              information, and may otherwise be omitted.
            </p>

            <pre
              class="language-ts good prettyprint prettyprinted"
              style=""
            ><span class="com">/**
 * POSTs the request to start coffee brewing.
 * @param amountLitres The amount to brew. Must fit the pot size!
 */</span><span class="pln">
brew</span><span class="pun">(</span><span class="pln">amountLitres</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">,</span><span class="pln"> logger</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Logger</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// ...</span><span class="pln">
</span><span class="pun">}</span></pre>
          </li>
        </ul>

        <h4
          id="parameter-property-comments"
          style="left: -46px; position: relative"
        >
          Parameter property comments<a
            href="#parameter-property-comments"
            alt="link to parameter-property-comments"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          A parameter property is when a class declares a field and a
          constructor parameter in a single declaration, by marking a parameter
          in the constructor. E.g.
          <code>constructor(private readonly foo: Foo)</code>, declares that the
          class has a <code>foo</code> field.
        </p>

        <p>
          To document these fields, use JSDoc's <code>@param</code> annotation.
          Editors display the description on constructor calls and property
          accesses.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">/** This class demonstrates how parameter properties are documented. */</span><span class="pln">
</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">ParamProps</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">/**
   * @param percolator The percolator used for brewing.
   * @param beans The beans to brew.
   */</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">(</span><span class="pln">
    </span><span class="kwd">private</span><span class="pln"> readonly percolator</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Percolator</span><span class="pun">,</span><span class="pln">
    </span><span class="kwd">private</span><span class="pln"> readonly beans</span><span class="pun">:</span><span class="pln"> </span><span class="typ">CoffeeBean</span><span class="pun">[])</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">/** This class demonstrates how ordinary fields are documented. */</span><span class="pln">
</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">OrdinaryClass</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">/** The bean that will be used in the next call to brew(). */</span><span class="pln">
  nextBean</span><span class="pun">:</span><span class="pln"> </span><span class="typ">CoffeeBean</span><span class="pun">;</span><span class="pln">

  </span><span class="kwd">constructor</span><span class="pun">(</span><span class="pln">initialBean</span><span class="pun">:</span><span class="pln"> </span><span class="typ">CoffeeBean</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">nextBean </span><span class="pun">=</span><span class="pln"> initialBean</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h4
          id="comments-when-calling-a-function"
          style="left: -46px; position: relative"
        >
          Comments when calling a function<a
            href="#comments-when-calling-a-function"
            alt="link to comments-when-calling-a-function"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          If needed, document parameters at call sites inline using block
          comments. Also consider named parameters using object literals and
          destructuring. The exact formatting and placement of the comment is
          not prescribed.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Inline block comments for parameters that'd be hard to understand:</span><span class="pln">
</span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Percolator</span><span class="pun">().</span><span class="pln">brew</span><span class="pun">(</span><span class="com">/* amountLitres= */</span><span class="pln"> </span><span class="lit">5</span><span class="pun">);</span><span class="pln">
</span><span class="com">// Also consider using named arguments and destructuring parameters (in brew's declaration):</span><span class="pln">
</span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Percolator</span><span class="pun">().</span><span class="pln">brew</span><span class="pun">({</span><span class="pln">amountLitres</span><span class="pun">:</span><span class="pln"> </span><span class="lit">5</span><span class="pun">});</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">/** An ancient {@link CoffeeBrewer} */</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">class</span><span class="pln"> </span><span class="typ">Percolator</span><span class="pln"> </span><span class="kwd">implements</span><span class="pln"> </span><span class="typ">CoffeeBrewer</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">/**
   * Brews coffee.
   * @param amountLitres The amount to brew. Must fit the pot size!
   */</span><span class="pln">
  brew</span><span class="pun">(</span><span class="pln">amountLitres</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="com">// This implementation creates terrible coffee, but whatever.</span><span class="pln">
    </span><span class="com">// TODO(b/12345): Improve percolator brewing.</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h4
          id="place-documentation-prior-to-decorators"
          style="left: -46px; position: relative"
        >
          Place documentation prior to decorators<a
            href="#place-documentation-prior-to-decorators"
            alt="link to place-documentation-prior-to-decorators"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          When a class, method, or property have both decorators like
          <code>@Component</code> and JsDoc, please make sure to write the JsDoc
          before the decorator.
        </p>

        <ul>
          <li>
            <p>
              Do not write JsDoc between the Decorator and the decorated
              statement.
            </p>

            <pre
              class="language-ts bad prettyprint prettyprinted"
              style=""
            ><span class="lit">@Component</span><span class="pun">({</span><span class="pln">
  selector</span><span class="pun">:</span><span class="pln"> </span><span class="str">'foo'</span><span class="pun">,</span><span class="pln">
  template</span><span class="pun">:</span><span class="pln"> </span><span class="str">'bar'</span><span class="pun">,</span><span class="pln">
</span><span class="pun">})</span><span class="pln">
</span><span class="com">/** Component that prints "bar". */</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">class</span><span class="pln"> </span><span class="typ">FooComponent</span><span class="pln"> </span><span class="pun">{}</span></pre>
          </li>
          <li>
            <p>Write the JsDoc block before the Decorator.</p>

            <pre
              class="language-ts good prettyprint prettyprinted"
              style=""
            ><span class="com">/** Component that prints "bar". */</span><span class="pln">
</span><span class="lit">@Component</span><span class="pun">({</span><span class="pln">
  selector</span><span class="pun">:</span><span class="pln"> </span><span class="str">'foo'</span><span class="pun">,</span><span class="pln">
  template</span><span class="pun">:</span><span class="pln"> </span><span class="str">'bar'</span><span class="pun">,</span><span class="pln">
</span><span class="pun">})</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">class</span><span class="pln"> </span><span class="typ">FooComponent</span><span class="pln"> </span><span class="pun">{}</span></pre>
          </li>
        </ul>

        <h2 id="language-rules" style="left: -46px; position: relative">
          Language Rules<a href="#language-rules" alt="link to language-rules"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h2>

        <h3 id="visibility" style="left: -46px; position: relative">
          Visibility<a href="#visibility" alt="link to visibility"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Restricting visibility of properties, methods, and entire types helps
          with keeping code decoupled.
        </p>

        <ul>
          <li>Limit symbol visibility as much as possible.</li>
          <li>
            Consider converting private methods to non-exported functions within
            the same file but outside of any class, and moving private
            properties into a separate, non-exported class.
          </li>
          <li>
            TypeScript symbols are public by default. Never use the
            <code>public</code> modifier except when declaring non-readonly
            public parameter properties (in constructors).
          </li>
        </ul>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">public</span><span class="pln"> bar </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Bar</span><span class="pun">();</span><span class="pln">  </span><span class="com">// BAD: public modifier not needed</span><span class="pln">

  </span><span class="kwd">constructor</span><span class="pun">(</span><span class="kwd">public</span><span class="pln"> readonly baz</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Baz</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">  </span><span class="com">// BAD: readonly implies it's a property which defaults to public</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  bar </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Bar</span><span class="pun">();</span><span class="pln">  </span><span class="com">// GOOD: public modifier not needed</span><span class="pln">

  </span><span class="kwd">constructor</span><span class="pun">(</span><span class="kwd">public</span><span class="pln"> baz</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Baz</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">  </span><span class="com">// public modifier allowed</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          See also <a href="#export-visibility">export visibility</a> below.
        </p>

        <h3 id="constructors" style="left: -46px; position: relative">
          Constructors<a href="#constructors" alt="link to constructors"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Constructor calls must use parentheses, even when no arguments are
          passed:
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> x </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Foo</span><span class="pun">;</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> x </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Foo</span><span class="pun">();</span></pre>

        <p>
          It is unnecessary to provide an empty constructor or one that simply
          delegates into its parent class because ES2015 provides a default
          class constructor if one is not specified. However constructors with
          parameter properties, modifiers or parameter decorators should not be
          omitted even if the body of the constructor is empty.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">UnnecessaryConstructor</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">UnnecessaryConstructorOverride</span><span class="pln"> extends </span><span class="typ">Base</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">constructor</span><span class="pun">(</span><span class="pln">value</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
      super</span><span class="pun">(</span><span class="pln">value</span><span class="pun">);</span><span class="pln">
    </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">DefaultConstructor</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">ParameterProperties</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">(</span><span class="kwd">private</span><span class="pln"> myService</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">ParameterDecorators</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">(</span><span class="lit">@SideEffectDecorator</span><span class="pln"> myService</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">NoInstantiation</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> </span><span class="kwd">constructor</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h3 id="class-members" style="left: -46px; position: relative">
          Class Members<a href="#class-members" alt="link to class-members"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <h4 id="private-fields" style="left: -46px; position: relative">
          No <code>#private</code> fields<a
            href="#private-fields"
            alt="link to private-fields"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>Do not use private fields (also known as private identifiers):</p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Clazz</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="pun">#</span><span class="pln">ident </span><span class="pun">=</span><span class="pln"> </span><span class="lit">1</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>Instead, use TypeScript's visibility annotations:</p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Clazz</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> ident </span><span class="pun">=</span><span class="pln"> </span><span class="lit">1</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

        <section class="zippy">
          Why?

          <p>
            Private identifiers cause substantial emit size and performance
            regressions when down-leveled by TypeScript, and are unsupported
            before ES2015. They can only be downleveled to ES2015, not lower. At
            the same time, they do not offer substantial benefits when static
            type checking is used to enforce visibility.
          </p>
        </section>

        <h4 id="use-readonly" style="left: -46px; position: relative">
          Use <code>readonly</code
          ><a href="#use-readonly" alt="link to use-readonly"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Mark properties that are never reassigned outside of the constructor
          with the <code>readonly</code> modifier (these need not be deeply
          immutable).
        </p>

        <h4 id="parameter-properties" style="left: -46px; position: relative">
          Parameter properties<a
            href="#parameter-properties"
            alt="link to parameter-properties"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Rather than plumbing an obvious initializer through to a class member,
          use a TypeScript
          <a
            href="https://www.typescriptlang.org/docs/handbook/classes.html#parameter-properties"
            >parameter property</a
          >.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> readonly barService</span><span class="pun">:</span><span class="pln"> </span><span class="typ">BarService</span><span class="pun">;</span><span class="pln">

  </span><span class="kwd">constructor</span><span class="pun">(</span><span class="pln">barService</span><span class="pun">:</span><span class="pln"> </span><span class="typ">BarService</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">barService </span><span class="pun">=</span><span class="pln"> barService</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">(</span><span class="kwd">private</span><span class="pln"> readonly barService</span><span class="pun">:</span><span class="pln"> </span><span class="typ">BarService</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          If the parameter property needs documentation,
          <a href="#parameter-property-comments"
            >use an <code>@param</code> JSDoc tag</a
          >.
        </p>

        <h4 id="field-initializers" style="left: -46px; position: relative">
          Field initializers<a
            href="#field-initializers"
            alt="link to field-initializers"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          If a class member is not a parameter, initialize it where it's
          declared, which sometimes lets you drop the constructor entirely.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> readonly userList</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">[];</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">userList </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[];</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> readonly userList</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">[]</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[];</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h4
          id="properties-used-outside-of-class-lexical-scope"
          style="left: -46px; position: relative"
        >
          Properties used outside of class lexical scope<a
            href="#properties-used-outside-of-class-lexical-scope"
            alt="link to properties-used-outside-of-class-lexical-scope"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Properties used from outside the lexical scope of their containing
          class, such as an AngularJS controller's properties used from a
          template, must not use <code>private</code> visibility, as they are
          used outside of the lexical scope of their containing class.
        </p>

        <p>
          Prefer <code>public</code> visibility for these properties, however
          <code>protected</code> visibility can also be used as needed. For
          example, Angular and Polymer template properties should use
          <code>public</code>, but AngularJS should use <code>protected</code>.
        </p>

        <p>
          TypeScript code must not not use <code>obj['foo']</code> to bypass the
          visibility of a property
        </p>

        <section class="zippy">
          Why?

          <p>
            When a property is <code>private</code>, you are declaring to both
            automated systems and humans that the property accesses are scoped
            to the methods of the declaring class, and they will rely on that.
            For example, a check for unused code will flag a private property
            that appears to be unused, even if some other file manages to bypass
            the visibility restriction.
          </p>

          <p>
            Though it may appear that <code>obj['foo']</code> can bypass
            visibility in the TypeScript compiler, this pattern can be broken by
            rearranging the build rules, and also violates
            <a href="#optimization-compatibility">optimization compatibility</a
            >.
          </p>
        </section>

        <h4>Getters and Setters (Accessors)</h4>

        <p>
          Getters and setters for class members may be used. The getter method
          must be a
          <a href="https://en.wikipedia.org/wiki/Pure_function"
            >pure function</a
          >
          (i.e., result is consistent and has no side effects). They are also
          useful as a means of restricting the visibility of internal or verbose
          implementation details (shown below).
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">(</span><span class="kwd">private</span><span class="pln"> readonly someService</span><span class="pun">:</span><span class="pln"> </span><span class="typ">SomeService</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">

  </span><span class="kwd">get</span><span class="pln"> someMember</span><span class="pun">():</span><span class="pln"> string </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">return</span><span class="pln"> </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">someService</span><span class="pun">.</span><span class="pln">someVariable</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">

  </span><span class="kwd">set</span><span class="pln"> someMember</span><span class="pun">(</span><span class="pln">newValue</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">someService</span><span class="pun">.</span><span class="pln">someVariable </span><span class="pun">=</span><span class="pln"> newValue</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          If an accessor is used to hide a class property, the hidden property
          may be prefixed or suffixed with any whole word, like
          <code>internal</code> or <code>wrapped</code>. When using these
          private properties, access the value through the accessor whenever
          possible. At least one accessor for a property must be non-trivial: do
          not define <q>pass-through</q> accessors only for the purpose of
          hiding a property. Instead, make the property public (or consider
          making it <code>readonly</code> rather than just defining a getter
          with no setter).
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> wrappedBar </span><span class="pun">=</span><span class="pln"> </span><span class="str">''</span><span class="pun">;</span><span class="pln">
  </span><span class="kwd">get</span><span class="pln"> bar</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">return</span><span class="pln"> </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">wrappedBar </span><span class="pun">||</span><span class="pln"> </span><span class="str">'bar'</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">

  </span><span class="kwd">set</span><span class="pln"> bar</span><span class="pun">(</span><span class="pln">wrapped</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">wrappedBar </span><span class="pun">=</span><span class="pln"> wrapped</span><span class="pun">.</span><span class="pln">trim</span><span class="pun">();</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">Bar</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> barInternal </span><span class="pun">=</span><span class="pln"> </span><span class="str">''</span><span class="pun">;</span><span class="pln">
  </span><span class="com">// Neither of these accessors have logic, so just make bar public.</span><span class="pln">
  </span><span class="kwd">get</span><span class="pln"> bar</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">return</span><span class="pln"> </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">barInternal</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">

  </span><span class="kwd">set</span><span class="pln"> bar</span><span class="pun">(</span><span class="pln">value</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">barInternal </span><span class="pun">=</span><span class="pln"> value</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h3
          id="primitive-types-wrapper-classes"
          style="left: -46px; position: relative"
        >
          Primitive Types &amp; Wrapper Classes<a
            href="#primitive-types-wrapper-classes"
            alt="link to primitive-types-wrapper-classes"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          TypeScript code must not instantiate the wrapper classes for the
          primitive types <code>String</code>, <code>Boolean</code>, and
          <code>Number</code>. Wrapper classes have surprising behaviour, such
          as <code>new Boolean(false)</code> evaluating to <code>true</code>.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> s </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">String</span><span class="pun">(</span><span class="str">'hello'</span><span class="pun">);</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> b </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Boolean</span><span class="pun">(</span><span class="kwd">false</span><span class="pun">);</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> n </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Number</span><span class="pun">(</span><span class="lit">5</span><span class="pun">);</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> s </span><span class="pun">=</span><span class="pln"> </span><span class="str">'hello'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> b </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">false</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> n </span><span class="pun">=</span><span class="pln"> </span><span class="lit">5</span><span class="pun">;</span></pre>

        <h3 id="array-constructor" style="left: -46px; position: relative">
          Array constructor<a
            href="#array-constructor"
            alt="link to array-constructor"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          TypeScript code must not use the <code>Array()</code> constructor,
          with or without <code>new</code>. It has confusing and contradictory
          usage:
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> a </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Array</span><span class="pun">(</span><span class="lit">2</span><span class="pun">);</span><span class="pln"> </span><span class="com">// [undefined, undefined]</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> b </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Array</span><span class="pun">(</span><span class="lit">2</span><span class="pun">,</span><span class="pln"> </span><span class="lit">3</span><span class="pun">);</span><span class="pln"> </span><span class="com">// [2, 3];</span></pre>

        <p>
          Instead, always use bracket notation to initialize arrays, or
          <code>from</code> to initialize an <code>Array</code> with a certain
          size:
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> a </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[</span><span class="lit">2</span><span class="pun">];</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> b </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[</span><span class="lit">2</span><span class="pun">,</span><span class="pln"> </span><span class="lit">3</span><span class="pun">];</span><span class="pln">

</span><span class="com">// Equivalent to Array(2):</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> c </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[];</span><span class="pln">
c</span><span class="pun">.</span><span class="pln">length </span><span class="pun">=</span><span class="pln"> </span><span class="lit">2</span><span class="pun">;</span><span class="pln">

</span><span class="com">// [0, 0, 0, 0, 0]</span><span class="pln">
</span><span class="typ">Array</span><span class="pun">.</span><span class="kwd">from</span><span class="pun">&lt;</span><span class="pln">number</span><span class="pun">&gt;({</span><span class="pln">length</span><span class="pun">:</span><span class="pln"> </span><span class="lit">5</span><span class="pun">}).</span><span class="pln">fill</span><span class="pun">(</span><span class="lit">0</span><span class="pun">);</span></pre>

        <h3 id="type-coercion" style="left: -46px; position: relative">
          Type coercion<a href="#type-coercion" alt="link to type-coercion"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          TypeScript code may use the <code>String()</code> and
          <code>Boolean()</code> (note: no <code>new</code>!) functions, string
          template literals, or <code>!!</code> to coerce types.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> bool </span><span class="pun">=</span><span class="pln"> </span><span class="typ">Boolean</span><span class="pun">(</span><span class="kwd">false</span><span class="pun">);</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> str </span><span class="pun">=</span><span class="pln"> </span><span class="typ">String</span><span class="pun">(</span><span class="pln">aNumber</span><span class="pun">);</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> bool2 </span><span class="pun">=</span><span class="pln"> </span><span class="pun">!!</span><span class="pln">str</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> str2 </span><span class="pun">=</span><span class="pln"> </span><span class="pun">`</span><span class="pln">result</span><span class="pun">:</span><span class="pln"> $</span><span class="pun">{</span><span class="pln">bool2</span><span class="pun">}`;</span></pre>

        <p>
          Using string concatenation to cast to string is discouraged, as we
          check that operands to the plus operator are of matching types.
        </p>

        <p>
          Code must use <code>Number()</code> to parse numeric values, and
          <em>must</em> check its return for <code>NaN</code> values explicitly,
          unless failing to parse is impossible from context.
        </p>

        <p class="note">
          Note: <code>Number('')</code>, <code>Number(' ')</code>, and
          <code>Number('\t')</code> would return <code>0</code> instead of
          <code>NaN</code>. <code>Number('Infinity')</code> and
          <code>Number('-Infinity')</code> would return
          <code>Infinity</code> and <code>-Infinity</code> respectively. These
          cases may require special handling.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> aNumber </span><span class="pun">=</span><span class="pln"> </span><span class="typ">Number</span><span class="pun">(</span><span class="str">'123'</span><span class="pun">);</span><span class="pln">
</span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">isNaN</span><span class="pun">(</span><span class="pln">aNumber</span><span class="pun">))</span><span class="pln"> </span><span class="kwd">throw</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Error</span><span class="pun">(...);</span><span class="pln">  </span><span class="com">// Handle NaN if the string might not contain a number</span><span class="pln">
assertFinite</span><span class="pun">(</span><span class="pln">aNumber</span><span class="pun">,</span><span class="pln"> </span><span class="pun">...);</span><span class="pln">                </span><span class="com">// Optional: if NaN cannot happen because it was validated before.</span></pre>

        <p>
          Code must not use unary plus (<code>+</code>) to coerce strings to
          numbers. Parsing numbers can fail, has surprising corner cases, and
          can be a code smell (parsing at the wrong layer). A unary plus is too
          easy to miss in code reviews given this.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> x </span><span class="pun">=</span><span class="pln"> </span><span class="pun">+</span><span class="pln">y</span><span class="pun">;</span></pre>

        <p>
          Code must also not use <code>parseInt</code> or
          <code>parseFloat</code> to parse numbers, except for non-base-10
          strings (see below). Both of those functions ignore trailing
          characters in the string, which can shadow error conditions (e.g.
          parsing <code>12 dwarves</code> as <code>12</code>).
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> n </span><span class="pun">=</span><span class="pln"> parseInt</span><span class="pun">(</span><span class="pln">someString</span><span class="pun">,</span><span class="pln"> </span><span class="lit">10</span><span class="pun">);</span><span class="pln">  </span><span class="com">// Error prone,</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> f </span><span class="pun">=</span><span class="pln"> parseFloat</span><span class="pun">(</span><span class="pln">someString</span><span class="pun">);</span><span class="pln">    </span><span class="com">// regardless of passing a radix.</span></pre>

        <p>
          Code that must parse using a radix <em>must</em> check that its input
          is a number before calling into <code>parseInt</code>;
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">if</span><span class="pln"> </span><span class="pun">(!</span><span class="str">/^[a-fA-F0-9]+$/</span><span class="pun">.</span><span class="pln">test</span><span class="pun">(</span><span class="pln">someString</span><span class="pun">))</span><span class="pln"> </span><span class="kwd">throw</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Error</span><span class="pun">(...);</span><span class="pln">
</span><span class="com">// Needed to parse hexadecimal.</span><span class="pln">
</span><span class="com">// tslint:disable-next-line:ban</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> n </span><span class="pun">=</span><span class="pln"> parseInt</span><span class="pun">(</span><span class="pln">someString</span><span class="pun">,</span><span class="pln"> </span><span class="lit">16</span><span class="pun">);</span><span class="pln">  </span><span class="com">// Only allowed for radix != 10</span></pre>

        <p>
          Use <code>Number()</code> followed by <code>Math.floor</code> or
          <code>Math.trunc</code> (where available) to parse integer numbers:
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">let</span><span class="pln"> f </span><span class="pun">=</span><span class="pln"> </span><span class="typ">Number</span><span class="pun">(</span><span class="pln">someString</span><span class="pun">);</span><span class="pln">
</span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">isNaN</span><span class="pun">(</span><span class="pln">f</span><span class="pun">))</span><span class="pln"> handleError</span><span class="pun">();</span><span class="pln">
f </span><span class="pun">=</span><span class="pln"> </span><span class="typ">Math</span><span class="pun">.</span><span class="pln">floor</span><span class="pun">(</span><span class="pln">f</span><span class="pun">);</span></pre>

        <p>
          Do not use explicit boolean coercions in conditional clauses that have
          implicit boolean coercion. Those are the conditions in an
          <code>if</code>, <code>for</code> and <code>while</code> statements.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> foo</span><span class="pun">:</span><span class="pln"> </span><span class="typ">MyInterface</span><span class="pun">|</span><span class="kwd">null</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">...;</span><span class="pln">
</span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(!!</span><span class="pln">foo</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{...}</span><span class="pln">
</span><span class="kwd">while</span><span class="pln"> </span><span class="pun">(!!</span><span class="pln">foo</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{...}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> foo</span><span class="pun">:</span><span class="pln"> </span><span class="typ">MyInterface</span><span class="pun">|</span><span class="kwd">null</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">...;</span><span class="pln">
</span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">foo</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{...}</span><span class="pln">
</span><span class="kwd">while</span><span class="pln"> </span><span class="pun">(</span><span class="pln">foo</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{...}</span></pre>

        <p>Code may use explicit comparisons:</p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Explicitly comparing &gt; 0 is OK:</span><span class="pln">
</span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">arr</span><span class="pun">.</span><span class="pln">length </span><span class="pun">&gt;</span><span class="pln"> </span><span class="lit">0</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{...}</span><span class="pln">
</span><span class="com">// so is relying on boolean coercion:</span><span class="pln">
</span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">arr</span><span class="pun">.</span><span class="pln">length</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{...}</span></pre>

        <h3 id="variables" style="left: -46px; position: relative">
          Variables<a href="#variables" alt="link to variables"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Always use <code>const</code> or <code>let</code> to declare
          variables. Use <code>const</code> by default, unless a variable needs
          to be reassigned. Never use <code>var</code>.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> otherValue</span><span class="pun">;</span><span class="pln">  </span><span class="com">// Use if "foo" never changes.</span><span class="pln">
</span><span class="kwd">let</span><span class="pln"> bar </span><span class="pun">=</span><span class="pln"> someValue</span><span class="pun">;</span><span class="pln">     </span><span class="com">// Use if "bar" is ever assigned into later on.</span></pre>

        <p>
          <code>const</code> and <code>let</code> are block scoped, like
          variables in most other languages. <code>var</code> in JavaScript is
          function scoped, which can cause difficult to understand bugs. Don't
          use it.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">var</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> someValue</span><span class="pun">;</span><span class="pln">     </span><span class="com">// Don't use - var scoping is complex and causes bugs.</span></pre>

        <p>Variables must not be used before their declaration.</p>

        <h3 id="exceptions" style="left: -46px; position: relative">
          Exceptions<a href="#exceptions" alt="link to exceptions"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Always use <code>new Error()</code> when instantiating exceptions,
          instead of just calling <code>Error()</code>. Both forms create a new
          <code>Error</code> instance, but using <code>new</code> is more
          consistent with how other objects are instantiated.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">throw</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Error</span><span class="pun">(</span><span class="str">'Foo is not a valid bar.'</span><span class="pun">);</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">throw</span><span class="pln"> </span><span class="typ">Error</span><span class="pun">(</span><span class="str">'Foo is not a valid bar.'</span><span class="pun">);</span></pre>

        <h3 id="iterating-objects" style="left: -46px; position: relative">
          Iterating objects<a
            href="#iterating-objects"
            alt="link to iterating-objects"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Iterating objects with <code>for (... in ...)</code> is error prone.
          It will include enumerable properties from the prototype chain.
        </p>

        <p>Do not use unfiltered <code>for (... in ...)</code> statements:</p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">const</span><span class="pln"> x in someObj</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// x could come from some parent prototype!</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          Either filter values explicitly with an <code>if</code> statement, or
          use <code>for (... of Object.keys(...))</code>.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">const</span><span class="pln"> x in someObj</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(!</span><span class="pln">someObj</span><span class="pun">.</span><span class="pln">hasOwnProperty</span><span class="pun">(</span><span class="pln">x</span><span class="pun">))</span><span class="pln"> </span><span class="kwd">continue</span><span class="pun">;</span><span class="pln">
  </span><span class="com">// now x was definitely defined on someObj</span><span class="pln">
</span><span class="pun">}</span><span class="pln">
</span><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">const</span><span class="pln"> x </span><span class="kwd">of</span><span class="pln"> </span><span class="typ">Object</span><span class="pun">.</span><span class="pln">keys</span><span class="pun">(</span><span class="pln">someObj</span><span class="pun">))</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="com">// note: for _of_!</span><span class="pln">
  </span><span class="com">// now x was definitely defined on someObj</span><span class="pln">
</span><span class="pun">}</span><span class="pln">
</span><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">const</span><span class="pln"> </span><span class="pun">[</span><span class="pln">key</span><span class="pun">,</span><span class="pln"> value</span><span class="pun">]</span><span class="pln"> </span><span class="kwd">of</span><span class="pln"> </span><span class="typ">Object</span><span class="pun">.</span><span class="pln">entries</span><span class="pun">(</span><span class="pln">someObj</span><span class="pun">))</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="com">// note: for _of_!</span><span class="pln">
  </span><span class="com">// now key was definitely defined on someObj</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h3 id="iterating-containers" style="left: -46px; position: relative">
          Iterating containers<a
            href="#iterating-containers"
            alt="link to iterating-containers"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Do not use <code>for (... in ...)</code> to iterate over arrays. It
          will counterintuitively give the array's indices (as strings!), not
          values:
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">const</span><span class="pln"> x in someArray</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// x is the index!</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          Use <code>for (... of someArr)</code> or vanilla
          <code>for</code> loops with indices to iterate over arrays.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">const</span><span class="pln"> x </span><span class="kwd">of</span><span class="pln"> someArr</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// x is a value of someArr.</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">let</span><span class="pln"> i </span><span class="pun">=</span><span class="pln"> </span><span class="lit">0</span><span class="pun">;</span><span class="pln"> i </span><span class="pun">&lt;</span><span class="pln"> someArr</span><span class="pun">.</span><span class="pln">length</span><span class="pun">;</span><span class="pln"> i</span><span class="pun">++)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// Explicitly count if the index is needed, otherwise use the for/of form.</span><span class="pln">
  </span><span class="kwd">const</span><span class="pln"> x </span><span class="pun">=</span><span class="pln"> someArr</span><span class="pun">[</span><span class="pln">i</span><span class="pun">];</span><span class="pln">
  </span><span class="com">// ...</span><span class="pln">
</span><span class="pun">}</span><span class="pln">
</span><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">const</span><span class="pln"> </span><span class="pun">[</span><span class="pln">i</span><span class="pun">,</span><span class="pln"> x</span><span class="pun">]</span><span class="pln"> </span><span class="kwd">of</span><span class="pln"> someArr</span><span class="pun">.</span><span class="pln">entries</span><span class="pun">())</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// Alternative version of the above.</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          Do not use <code>Array.prototype.forEach</code>,
          <code>Set.prototype.forEach</code>, and
          <code>Map.prototype.forEach</code>. They make code harder to debug and
          defeat some useful compiler checks (e.g. reachability).
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="pln">someArr</span><span class="pun">.</span><span class="pln">forEach</span><span class="pun">((</span><span class="pln">item</span><span class="pun">,</span><span class="pln"> index</span><span class="pun">)</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  someFn</span><span class="pun">(</span><span class="pln">item</span><span class="pun">,</span><span class="pln"> index</span><span class="pun">);</span><span class="pln">
</span><span class="pun">});</span></pre>

        <section class="zippy">
          Why?

          <p>Consider this code:</p>

          <pre
            class="language-ts bad prettyprint prettyprinted"
            style=""
          ><span class="kwd">let</span><span class="pln"> x</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">|</span><span class="kwd">null</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="str">'abc'</span><span class="pun">;</span><span class="pln">
myArray</span><span class="pun">.</span><span class="pln">forEach</span><span class="pun">(()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> x</span><span class="pun">.</span><span class="pln">charAt</span><span class="pun">(</span><span class="lit">0</span><span class="pun">);</span><span class="pln"> </span><span class="pun">});</span></pre>

          <p>
            You can recognize that this code is fine: <code>x</code> isn't null
            and it doesn't change before it is accessed. But the compiler cannot
            know that this <code>.forEach()</code> call doesn't hang on to the
            closure that was passed in and call it at some later point, maybe
            after <code>x</code> was set to null, so it flags this code as an
            error. The equivalent for-of loop is fine.
          </p>

          <p>
            <a
              href="https://www.typescriptlang.org/play?#code/DYUwLgBAHgXBDOYBOBLAdgcwD5oK7GAgF4IByAQwCMBjUgbgCgBtAXQDoAzAeyQFFzqACwAUwgJTEAfBADeDCNDZDySAIJhhABjGMAvjoYNQkAJ5xEqTDnyESFGvQbckEYdS5pEEAPoQuHCFYJOQUTJUEVdS0DXQYgA"
              >See the error and non-error in the playground</a
            >
          </p>

          <p>
            In practice, variations of this limitation of control flow analysis
            show up in more complex codepaths where it is more surprising.
          </p>
        </section>

        <h3
          id="using-the-spread-operator"
          style="left: -46px; position: relative"
        >
          Using the spread operator<a
            href="#using-the-spread-operator"
            alt="link to using-the-spread-operator"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Using the spread operator <code>[...foo]; {...bar}</code> is a
          convenient shorthand for copying arrays and objects. When using the
          spread operator on objects, later values replace earlier values at the
          same key.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  num</span><span class="pun">:</span><span class="pln"> </span><span class="lit">1</span><span class="pun">,</span><span class="pln">
</span><span class="pun">};</span><span class="pln">

</span><span class="kwd">const</span><span class="pln"> foo2 </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="pun">...</span><span class="pln">foo</span><span class="pun">,</span><span class="pln">
  num</span><span class="pun">:</span><span class="pln"> </span><span class="lit">5</span><span class="pun">,</span><span class="pln">
</span><span class="pun">};</span><span class="pln">

</span><span class="kwd">const</span><span class="pln"> foo3 </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  num</span><span class="pun">:</span><span class="pln"> </span><span class="lit">5</span><span class="pun">,</span><span class="pln">
  </span><span class="pun">...</span><span class="pln">foo</span><span class="pun">,</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

foo2</span><span class="pun">.</span><span class="pln">num </span><span class="pun">===</span><span class="pln"> </span><span class="lit">5</span><span class="pun">;</span><span class="pln">
foo3</span><span class="pun">.</span><span class="pln">num </span><span class="pun">===</span><span class="pln"> </span><span class="lit">1</span><span class="pun">;</span><span class="pln">
</span></pre>

        <p>
          When using the spread operator, the value being spread must match what
          is being created. That is, when creating an object, only objects may
          be used with the spread operator; when creating an array, only spread
          iterables. Primitives, including <code>null</code> and
          <code>undefined</code>, may never be spread.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">num</span><span class="pun">:</span><span class="pln"> </span><span class="lit">7</span><span class="pun">};</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> bar </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">num</span><span class="pun">:</span><span class="pln"> </span><span class="lit">5</span><span class="pun">,</span><span class="pln"> </span><span class="pun">...(</span><span class="pln">shouldUseFoo </span><span class="pun">&amp;&amp;</span><span class="pln"> foo</span><span class="pun">)};</span><span class="pln"> </span><span class="com">// might be undefined</span><span class="pln">

</span><span class="com">// Creates {0: 'a', 1: 'b', 2: 'c'} but has no length</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> fooStrings </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[</span><span class="str">'a'</span><span class="pun">,</span><span class="pln"> </span><span class="str">'b'</span><span class="pun">,</span><span class="pln"> </span><span class="str">'c'</span><span class="pun">];</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> ids </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{...</span><span class="pln">fooStrings</span><span class="pun">};</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> shouldUseFoo </span><span class="pun">?</span><span class="pln"> </span><span class="pun">{</span><span class="pln">num</span><span class="pun">:</span><span class="pln"> </span><span class="lit">7</span><span class="pun">}</span><span class="pln"> </span><span class="pun">:</span><span class="pln"> </span><span class="pun">{};</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> bar </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">num</span><span class="pun">:</span><span class="pln"> </span><span class="lit">5</span><span class="pun">,</span><span class="pln"> </span><span class="pun">...</span><span class="pln">foo</span><span class="pun">};</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> fooStrings </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[</span><span class="str">'a'</span><span class="pun">,</span><span class="pln"> </span><span class="str">'b'</span><span class="pun">,</span><span class="pln"> </span><span class="str">'c'</span><span class="pun">];</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> ids </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[...</span><span class="pln">fooStrings</span><span class="pun">,</span><span class="pln"> </span><span class="str">'d'</span><span class="pun">,</span><span class="pln"> </span><span class="str">'e'</span><span class="pun">];</span></pre>

        <h3
          id="control-flow-statements-blocks"
          style="left: -46px; position: relative"
        >
          Control flow statements &amp; blocks<a
            href="#control-flow-statements-blocks"
            alt="link to control-flow-statements-blocks"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Control flow statements spanning multiple lines always use blocks for
          the containing code.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">let</span><span class="pln"> i </span><span class="pun">=</span><span class="pln"> </span><span class="lit">0</span><span class="pun">;</span><span class="pln"> i </span><span class="pun">&lt;</span><span class="pln"> x</span><span class="pun">;</span><span class="pln"> i</span><span class="pun">++)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  doSomethingWith</span><span class="pun">(</span><span class="pln">i</span><span class="pun">);</span><span class="pln">
  andSomeMore</span><span class="pun">();</span><span class="pln">
</span><span class="pun">}</span><span class="pln">
</span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">x</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  doSomethingWithALongMethodName</span><span class="pun">(</span><span class="pln">x</span><span class="pun">);</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">x</span><span class="pun">)</span><span class="pln">
  x</span><span class="pun">.</span><span class="pln">doFoo</span><span class="pun">();</span><span class="pln">
</span><span class="kwd">for</span><span class="pln"> </span><span class="pun">(</span><span class="kwd">let</span><span class="pln"> i </span><span class="pun">=</span><span class="pln"> </span><span class="lit">0</span><span class="pun">;</span><span class="pln"> i </span><span class="pun">&lt;</span><span class="pln"> x</span><span class="pun">;</span><span class="pln"> i</span><span class="pun">++)</span><span class="pln">
  doSomethingWithALongMethodName</span><span class="pun">(</span><span class="pln">i</span><span class="pun">);</span></pre>

        <p>
          The exception is that <code>if</code> statements fitting on one line
          may elide the block.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">x</span><span class="pun">)</span><span class="pln"> x</span><span class="pun">.</span><span class="pln">doFoo</span><span class="pun">();</span></pre>

        <h3 id="switch-statements" style="left: -46px; position: relative">
          Switch Statements<a
            href="#switch-statements"
            alt="link to switch-statements"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          All <code>switch</code> statements must contain a
          <code>default</code> statement group, even if it contains no code.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">switch</span><span class="pln"> </span><span class="pun">(</span><span class="pln">x</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">case</span><span class="pln"> Y</span><span class="pun">:</span><span class="pln">
    doSomethingElse</span><span class="pun">();</span><span class="pln">
    </span><span class="kwd">break</span><span class="pun">;</span><span class="pln">
  </span><span class="kwd">default</span><span class="pun">:</span><span class="pln">
    </span><span class="com">// nothing to do.</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          Non-empty statement groups (<code>case ...</code>) may not fall
          through (enforced by the compiler):
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">switch</span><span class="pln"> </span><span class="pun">(</span><span class="pln">x</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">case</span><span class="pln"> X</span><span class="pun">:</span><span class="pln">
    doSomething</span><span class="pun">();</span><span class="pln">
    </span><span class="com">// fall through - not allowed!</span><span class="pln">
  </span><span class="kwd">case</span><span class="pln"> Y</span><span class="pun">:</span><span class="pln">
    </span><span class="com">// ...</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>Empty statement groups are allowed to fall through:</p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">switch</span><span class="pln"> </span><span class="pun">(</span><span class="pln">x</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">case</span><span class="pln"> X</span><span class="pun">:</span><span class="pln">
  </span><span class="kwd">case</span><span class="pln"> Y</span><span class="pun">:</span><span class="pln">
    doSomething</span><span class="pun">();</span><span class="pln">
    </span><span class="kwd">break</span><span class="pun">;</span><span class="pln">
  </span><span class="kwd">default</span><span class="pun">:</span><span class="pln"> </span><span class="com">// nothing to do.</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h3 id="equality-checks" style="left: -46px; position: relative">
          Equality Checks<a
            href="#equality-checks"
            alt="link to equality-checks"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Always use triple equals (<code>===</code>) and not equals
          (<code>!==</code>). The double equality operators cause error prone
          type coercions that are hard to understand and slower to implement for
          JavaScript Virtual Machines. See also the
          <a href="https://dorey.github.io/JavaScript-Equality-Table/"
            >JavaScript equality table</a
          >.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">foo </span><span class="pun">==</span><span class="pln"> </span><span class="str">'bar'</span><span class="pln"> </span><span class="pun">||</span><span class="pln"> baz </span><span class="pun">!=</span><span class="pln"> bam</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// Hard to understand behaviour due to type coercion.</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">foo </span><span class="pun">===</span><span class="pln"> </span><span class="str">'bar'</span><span class="pln"> </span><span class="pun">||</span><span class="pln"> baz </span><span class="pun">!==</span><span class="pln"> bam</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// All good here.</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p class="exception">
          <strong>Exception</strong>: Comparisons to the literal
          <code>null</code> value may use the <code>==</code> and
          <code>!=</code> operators to cover both <code>null</code> and
          <code>undefined</code> values.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">foo </span><span class="pun">==</span><span class="pln"> </span><span class="kwd">null</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// Will trigger when foo is null or undefined.</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h3 id="function-declarations" style="left: -46px; position: relative">
          Function Declarations<a
            href="#function-declarations"
            alt="link to function-declarations"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Use <code>function foo() { ... }</code> to declare named functions,
          including functions in nested scopes, e.g. within another function.
        </p>

        <p>
          Use function declarations instead of assigning a function expression
          into a local variable (<del
            ><code>const x = function() {...};</code></del
          >). TypeScript already disallows rebinding functions, so preventing
          overwriting a function declaration by using <code>const</code> is
          unnecessary.
        </p>

        <p class="exception">
          Exception: Use arrow functions assigned to variables instead of
          function declarations if the function accesses the outer scope's
          <code>this</code>.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">function</span><span class="pln"> foo</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">}</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Given the above declaration, this won't compile:</span><span class="pln">
foo </span><span class="pun">=</span><span class="pln"> </span><span class="pun">()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="lit">3</span><span class="pun">;</span><span class="pln">  </span><span class="com">// ERROR: Invalid left-hand side of assignment expression.</span><span class="pln">

</span><span class="com">// So declarations like this are unnecessary.</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">function</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">}</span></pre>

        <blockquote>
          <p>
            Note the difference between function declarations (<code
              >function foo() {}</code
            >) discussed here, and function expressions (<del
              ><code>doSomethingWith(function() {});</code></del
            >) discussed <a href="#function-expressions">below</a>.
          </p>
        </blockquote>

        <p>
          Top level arrow functions <em>may</em> be used to explicitly declare
          that a function implements an interface.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">SearchFunction</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="pun">(</span><span class="pln">source</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">,</span><span class="pln"> subString</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">):</span><span class="pln"> boolean</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">const</span><span class="pln"> fooSearch</span><span class="pun">:</span><span class="pln"> </span><span class="typ">SearchFunction</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">(</span><span class="pln">source</span><span class="pun">,</span><span class="pln"> subString</span><span class="pun">)</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">};</span></pre>

        <h3 id="function-expressions" style="left: -46px; position: relative">
          Function Expressions<a
            href="#function-expressions"
            alt="link to function-expressions"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <h4
          id="use-arrow-functions-in-expressions"
          style="left: -46px; position: relative"
        >
          Use arrow functions in expressions<a
            href="#use-arrow-functions-in-expressions"
            alt="link to use-arrow-functions-in-expressions"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Always use arrow functions instead of pre-ES6 function expressions
          defined with the <code>function</code> keyword.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="pln">bar</span><span class="pun">(()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">doSomething</span><span class="pun">();</span><span class="pln"> </span><span class="pun">})</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="pln">bar</span><span class="pun">(</span><span class="kwd">function</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">})</span></pre>

        <p>
          Function expressions (defined with the <code>function</code> keyword)
          may only be used if code has to dynamically rebind the
          <code>this</code> pointer, but code <em>should not</em> rebind the
          <code>this</code> pointer in general. Code in regular functions (as
          opposed to arrow functions and methods) <em>should not</em> access
          <code>this</code>.
        </p>

        <h4
          id="expression-bodies-vs-block-bodies"
          style="left: -46px; position: relative"
        >
          Expression bodies vs block bodies<a
            href="#expression-bodies-vs-block-bodies"
            alt="link to expression-bodies-vs-block-bodies"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Use arrow functions with expressions or blocks as their body as
          appropriate.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Top level functions use function declarations.</span><span class="pln">
</span><span class="kwd">function</span><span class="pln"> someFunction</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// Block arrow function bodies, i.e. bodies with =&gt; { }, are fine:</span><span class="pln">
  </span><span class="kwd">const</span><span class="pln"> receipts </span><span class="pun">=</span><span class="pln"> books</span><span class="pun">.</span><span class="pln">map</span><span class="pun">((</span><span class="pln">b</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Book</span><span class="pun">)</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">const</span><span class="pln"> receipt </span><span class="pun">=</span><span class="pln"> payMoney</span><span class="pun">(</span><span class="pln">b</span><span class="pun">.</span><span class="pln">price</span><span class="pun">);</span><span class="pln">
    recordTransaction</span><span class="pun">(</span><span class="pln">receipt</span><span class="pun">);</span><span class="pln">
    </span><span class="kwd">return</span><span class="pln"> receipt</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">});</span><span class="pln">

  </span><span class="com">// Expression bodies are fine, too, if the return value is used:</span><span class="pln">
  </span><span class="kwd">const</span><span class="pln"> longThings </span><span class="pun">=</span><span class="pln"> myValues</span><span class="pun">.</span><span class="pln">filter</span><span class="pun">(</span><span class="pln">v </span><span class="pun">=&gt;</span><span class="pln"> v</span><span class="pun">.</span><span class="pln">length </span><span class="pun">&gt;</span><span class="pln"> </span><span class="lit">1000</span><span class="pun">).</span><span class="pln">map</span><span class="pun">(</span><span class="pln">v </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="typ">String</span><span class="pun">(</span><span class="pln">v</span><span class="pun">));</span><span class="pln">

  </span><span class="kwd">function</span><span class="pln"> payMoney</span><span class="pun">(</span><span class="pln">amount</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="com">// function declarations are fine, but don't access `this` in them.</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          Only use an expression body if the return value of the function is
          actually used.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// BAD: use a block ({ ... }) if the return value of the function is not used.</span><span class="pln">
myPromise</span><span class="pun">.</span><span class="pln">then</span><span class="pun">(</span><span class="pln">v </span><span class="pun">=&gt;</span><span class="pln"> console</span><span class="pun">.</span><span class="pln">log</span><span class="pun">(</span><span class="pln">v</span><span class="pun">));</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// GOOD: return value is unused, use a block body.</span><span class="pln">
myPromise</span><span class="pun">.</span><span class="pln">then</span><span class="pun">(</span><span class="pln">v </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  console</span><span class="pun">.</span><span class="pln">log</span><span class="pun">(</span><span class="pln">v</span><span class="pun">);</span><span class="pln">
</span><span class="pun">});</span><span class="pln">
</span><span class="com">// GOOD: code may use blocks for readability.</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> transformed </span><span class="pun">=</span><span class="pln"> </span><span class="pun">[</span><span class="lit">1</span><span class="pun">,</span><span class="pln"> </span><span class="lit">2</span><span class="pun">,</span><span class="pln"> </span><span class="lit">3</span><span class="pun">].</span><span class="pln">map</span><span class="pun">(</span><span class="pln">v </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">const</span><span class="pln"> intermediate </span><span class="pun">=</span><span class="pln"> someComplicatedExpr</span><span class="pun">(</span><span class="pln">v</span><span class="pun">);</span><span class="pln">
  </span><span class="kwd">const</span><span class="pln"> more </span><span class="pun">=</span><span class="pln"> acrossManyLines</span><span class="pun">(</span><span class="pln">intermediate</span><span class="pun">);</span><span class="pln">
  </span><span class="kwd">return</span><span class="pln"> worthWrapping</span><span class="pun">(</span><span class="pln">more</span><span class="pun">);</span><span class="pln">
</span><span class="pun">});</span></pre>

        <h4 id="rebinding-this" style="left: -46px; position: relative">
          Rebinding <code>this</code
          ><a href="#rebinding-this" alt="link to rebinding-this"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Function expressions must not use <code>this</code> unless they
          specifically exist to rebind the <code>this</code> pointer. Rebinding
          <code>this</code> can in most cases be avoided by using arrow
          functions or explicit parameters.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">function</span><span class="pln"> clickHandler</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// Bad: what's `this` in this context?</span><span class="pln">
  </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">textContent </span><span class="pun">=</span><span class="pln"> </span><span class="str">'Hello'</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">
</span><span class="com">// Bad: the `this` pointer reference is implicitly set to document.body.</span><span class="pln">
document</span><span class="pun">.</span><span class="pln">body</span><span class="pun">.</span><span class="pln">onclick </span><span class="pun">=</span><span class="pln"> clickHandler</span><span class="pun">;</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Good: explicitly reference the object from an arrow function.</span><span class="pln">
document</span><span class="pun">.</span><span class="pln">body</span><span class="pun">.</span><span class="pln">onclick </span><span class="pun">=</span><span class="pln"> </span><span class="pun">()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> document</span><span class="pun">.</span><span class="pln">body</span><span class="pun">.</span><span class="pln">textContent </span><span class="pun">=</span><span class="pln"> </span><span class="str">'hello'</span><span class="pun">;</span><span class="pln"> </span><span class="pun">};</span><span class="pln">
</span><span class="com">// Alternatively: take an explicit parameter</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> setTextFn </span><span class="pun">=</span><span class="pln"> </span><span class="pun">(</span><span class="pln">e</span><span class="pun">:</span><span class="pln"> </span><span class="typ">HTMLElement</span><span class="pun">)</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> e</span><span class="pun">.</span><span class="pln">textContent </span><span class="pun">=</span><span class="pln"> </span><span class="str">'hello'</span><span class="pun">;</span><span class="pln"> </span><span class="pun">};</span><span class="pln">
document</span><span class="pun">.</span><span class="pln">body</span><span class="pun">.</span><span class="pln">onclick </span><span class="pun">=</span><span class="pln"> setTextFn</span><span class="pun">.</span><span class="pln">bind</span><span class="pun">(</span><span class="kwd">null</span><span class="pun">,</span><span class="pln"> document</span><span class="pun">.</span><span class="pln">body</span><span class="pun">);</span></pre>

        <h4
          id="arrow-functions-as-properties"
          style="left: -46px; position: relative"
        >
          Arrow functions as properties<a
            href="#arrow-functions-as-properties"
            alt="link to arrow-functions-as-properties"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Classes usually <em>should not</em> contain properties initialized to
          arrow functions. Arrow function properties require the calling
          function to understand that the callee's <code>this</code> is already
          bound, which increases confusion about what <code>this</code> is, and
          call sites and references using such handlers look broken (i.e.
          require non-local knowledge to determine that they are correct). Code
          <em>should</em> always use arrow functions to call instance methods
          (<code>const handler = (x) =&gt; { this.listener(x); };</code>), and
          <em>should not</em> obtain or pass references to instance methods
          (<del><code>const handler = this.listener; handler(x);</code></del
          >).
        </p>

        <blockquote>
          <p class="note">
            Note: in some specific situations, e.g. when binding functions in a
            template, arrow functions as properties are useful and create much
            more readable code. Use judgement with this rule. Also, see the
            <a href="#event-handlers"><code>Event Handlers</code></a> section
            below.
          </p>
        </blockquote>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">DelayHandler</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="com">// Problem: `this` is not preserved in the callback. `this` in the callback</span><span class="pln">
    </span><span class="com">// will not be an instance of DelayHandler.</span><span class="pln">
    setTimeout</span><span class="pun">(</span><span class="kwd">this</span><span class="pun">.</span><span class="pln">patienceTracker</span><span class="pun">,</span><span class="pln"> </span><span class="lit">5000</span><span class="pun">);</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> patienceTracker</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">waitedPatiently </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">true</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Arrow functions usually should not be properties.</span><span class="pln">
</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">DelayHandler</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="com">// Bad: this code looks like it forgot to bind `this`.</span><span class="pln">
    setTimeout</span><span class="pun">(</span><span class="kwd">this</span><span class="pun">.</span><span class="pln">patienceTracker</span><span class="pun">,</span><span class="pln"> </span><span class="lit">5000</span><span class="pun">);</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> patienceTracker </span><span class="pun">=</span><span class="pln"> </span><span class="pun">()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">waitedPatiently </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">true</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Explicitly manage `this` at call time.</span><span class="pln">
</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">DelayHandler</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">constructor</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="com">// Use anonymous functions if possible.</span><span class="pln">
    setTimeout</span><span class="pun">(()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
      </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">patienceTracker</span><span class="pun">();</span><span class="pln">
    </span><span class="pun">},</span><span class="pln"> </span><span class="lit">5000</span><span class="pun">);</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> patienceTracker</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">waitedPatiently </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">true</span><span class="pun">;</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h4 id="event-handlers" style="left: -46px; position: relative">
          Event Handlers<a href="#event-handlers" alt="link to event-handlers"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Event handlers <em>may</em> use arrow functions when there is no need
          to uninstall the handler (for example, if the event is emitted by the
          class itself). If the handler must be uninstalled, arrow function
          properties are the right approach, because they automatically capture
          <code>this</code> and provide a stable reference to uninstall.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Event handlers may be anonymous functions or arrow function properties.</span><span class="pln">
</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">Component</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  onAttached</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="com">// The event is emitted by this class, no need to uninstall.</span><span class="pln">
    </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">addEventListener</span><span class="pun">(</span><span class="str">'click'</span><span class="pun">,</span><span class="pln"> </span><span class="pun">()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
      </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">listener</span><span class="pun">();</span><span class="pln">
    </span><span class="pun">});</span><span class="pln">
    </span><span class="com">// this.listener is a stable reference, we can uninstall it later.</span><span class="pln">
    window</span><span class="pun">.</span><span class="pln">addEventListener</span><span class="pun">(</span><span class="str">'onbeforeunload'</span><span class="pun">,</span><span class="pln"> </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">listener</span><span class="pun">);</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
  onDetached</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="com">// The event is emitted by window. If we don't uninstall, this.listener will</span><span class="pln">
    </span><span class="com">// keep a reference to `this` because it's bound, causing a memory leak.</span><span class="pln">
    window</span><span class="pun">.</span><span class="pln">removeEventListener</span><span class="pun">(</span><span class="str">'onbeforeunload'</span><span class="pun">,</span><span class="pln"> </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">listener</span><span class="pun">);</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
  </span><span class="com">// An arrow function stored in a property is bound to `this` automatically.</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> listener </span><span class="pun">=</span><span class="pln"> </span><span class="pun">()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    confirm</span><span class="pun">(</span><span class="str">'Do you want to exit the page?'</span><span class="pun">);</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          Do not use <code>bind</code> in the expression that installs an event
          handler, because it creates a temporary reference that can't be
          uninstalled.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Binding listeners creates a temporary reference that prevents uninstalling.</span><span class="pln">
</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">Component</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  onAttached</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="com">// This creates a temporary reference that we won't be able to uninstall</span><span class="pln">
    window</span><span class="pun">.</span><span class="pln">addEventListener</span><span class="pun">(</span><span class="str">'onbeforeunload'</span><span class="pun">,</span><span class="pln"> </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">listener</span><span class="pun">.</span><span class="pln">bind</span><span class="pun">(</span><span class="kwd">this</span><span class="pun">));</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
  onDetached</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="com">// This bind creates a different reference, so this line does nothing.</span><span class="pln">
    window</span><span class="pun">.</span><span class="pln">removeEventListener</span><span class="pun">(</span><span class="str">'onbeforeunload'</span><span class="pun">,</span><span class="pln"> </span><span class="kwd">this</span><span class="pun">.</span><span class="pln">listener</span><span class="pun">.</span><span class="pln">bind</span><span class="pun">(</span><span class="kwd">this</span><span class="pun">));</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
  </span><span class="kwd">private</span><span class="pln"> listener</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    confirm</span><span class="pun">(</span><span class="str">'Do you want to exit the page?'</span><span class="pun">);</span><span class="pln">
  </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h3
          id="automatic-semicolon-insertion"
          style="left: -46px; position: relative"
        >
          Automatic Semicolon Insertion<a
            href="#automatic-semicolon-insertion"
            alt="link to automatic-semicolon-insertion"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Do not rely on Automatic Semicolon Insertion (ASI). Explicitly
          terminate all statements using a semicolon. This prevents bugs due to
          incorrect semicolon insertions and ensures compatibility with tools
          with limited ASI support (e.g. clang-format).
        </p>

        <h3 id="ts-ignore" style="left: -46px; position: relative">
          @ts-ignore<a href="#ts-ignore" alt="link to ts-ignore"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Do not use <code>@ts-ignore</code>. It superficially seems to be an
          easy way to <q>fix</q> a compiler error, but in practice, a specific
          compiler error is often caused by a larger problem that can be fixed
          more directly.
        </p>

        <p>
          For example, if you are using <code>@ts-ignore</code> to suppress a
          type error, then it's hard to predict what types the surrounding code
          will end up seeing. For many type errors, the advice in
          <a href="#any">how to best use <code>any</code></a> is useful.
        </p>

        <h3
          id="type-and-non-nullability-assertions"
          style="left: -46px; position: relative"
        >
          Type and Non-nullability Assertions<a
            href="#type-and-non-nullability-assertions"
            alt="link to type-and-non-nullability-assertions"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Type assertions (<code>x as SomeType</code>) and non-nullability
          assertions (<code>y!</code>) are unsafe. Both only silence the
          TypeScript compiler, but do not insert any runtime checks to match
          these assertions, so they can cause your program to crash at runtime.
        </p>

        <p>
          Because of this, you <em>should not</em> use type and non-nullability
          assertions without an obvious or explicit reason for doing so.
        </p>

        <p>Instead of the following:</p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="pun">(</span><span class="pln">x as </span><span class="typ">Foo</span><span class="pun">).</span><span class="pln">foo</span><span class="pun">();</span><span class="pln">

y</span><span class="pun">!.</span><span class="pln">bar</span><span class="pun">();</span></pre>

        <p>
          When you want to assert a type or non-nullability the best answer is
          to explicitly write a runtime check that performs that check.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// assuming Foo is a class.</span><span class="pln">
</span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">x </span><span class="kwd">instanceof</span><span class="pln"> </span><span class="typ">Foo</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  x</span><span class="pun">.</span><span class="pln">foo</span><span class="pun">();</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">y</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  y</span><span class="pun">.</span><span class="pln">bar</span><span class="pun">();</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          Sometimes due to some local property of your code you can be sure that
          the assertion form is safe. In those situations, you
          <em>should</em> add clarification to explain why you are ok with the
          unsafe behavior:
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// x is a Foo, because ...</span><span class="pln">
</span><span class="pun">(</span><span class="pln">x as </span><span class="typ">Foo</span><span class="pun">).</span><span class="pln">foo</span><span class="pun">();</span><span class="pln">

</span><span class="com">// y cannot be null, because ...</span><span class="pln">
y</span><span class="pun">!.</span><span class="pln">bar</span><span class="pun">();</span></pre>

        <p>
          If the reasoning behind a type or non-nullability assertion is
          obvious, the comments may not be necessary. For example, generated
          proto code is always nullable, but perhaps it is well-known in the
          context of the code that certain fields are always provided by the
          backend. Use your judgement.
        </p>

        <h4 id="type-assertions-syntax" style="left: -46px; position: relative">
          Type Assertions Syntax<a
            href="#type-assertions-syntax"
            alt="link to type-assertions-syntax"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Type assertions must use the <code>as</code> syntax (as opposed to the
          angle brackets syntax). This enforces parentheses around the assertion
          when accessing a member.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> x </span><span class="pun">=</span><span class="pln"> </span><span class="pun">(&lt;</span><span class="typ">Foo</span><span class="pun">&gt;</span><span class="pln">z</span><span class="pun">).</span><span class="pln">length</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> y </span><span class="pun">=</span><span class="pln"> </span><span class="pun">&lt;</span><span class="typ">Foo</span><span class="pun">&gt;</span><span class="pln">z</span><span class="pun">.</span><span class="pln">length</span><span class="pun">;</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> x </span><span class="pun">=</span><span class="pln"> </span><span class="pun">(</span><span class="pln">z as </span><span class="typ">Foo</span><span class="pun">).</span><span class="pln">length</span><span class="pun">;</span></pre>

        <h4
          id="type-assertions-and-object-literals"
          style="left: -46px; position: relative"
        >
          Type Assertions and Object Literals<a
            href="#type-assertions-and-object-literals"
            alt="link to type-assertions-and-object-literals"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Use type annotations (<code>: Foo</code>) instead of type assertions
          (<code>as Foo</code>) to specify the type of an object literal. This
          allows detecting refactoring bugs when the fields of an interface
          change over time.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  bar</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">;</span><span class="pln">
  baz</span><span class="pun">?:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">  </span><span class="com">// was "bam", but later renamed to "baz".</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">const</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  bar</span><span class="pun">:</span><span class="pln"> </span><span class="lit">123</span><span class="pun">,</span><span class="pln">
  bam</span><span class="pun">:</span><span class="pln"> </span><span class="str">'abc'</span><span class="pun">,</span><span class="pln">  </span><span class="com">// no error!</span><span class="pln">
</span><span class="pun">}</span><span class="pln"> as </span><span class="typ">Foo</span><span class="pun">;</span><span class="pln">

</span><span class="kwd">function</span><span class="pln"> func</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">return</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    bar</span><span class="pun">:</span><span class="pln"> </span><span class="lit">123</span><span class="pun">,</span><span class="pln">
    bam</span><span class="pun">:</span><span class="pln"> </span><span class="str">'abc'</span><span class="pun">,</span><span class="pln">  </span><span class="com">// no error!</span><span class="pln">
  </span><span class="pun">}</span><span class="pln"> as </span><span class="typ">Foo</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  bar</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">;</span><span class="pln">
  baz</span><span class="pun">?:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">const</span><span class="pln"> foo</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  bar</span><span class="pun">:</span><span class="pln"> </span><span class="lit">123</span><span class="pun">,</span><span class="pln">
  bam</span><span class="pun">:</span><span class="pln"> </span><span class="str">'abc'</span><span class="pun">,</span><span class="pln">  </span><span class="com">// complains about "bam" not being defined on Foo.</span><span class="pln">
</span><span class="pun">};</span><span class="pln">

</span><span class="kwd">function</span><span class="pln"> func</span><span class="pun">():</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">return</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    bar</span><span class="pun">:</span><span class="pln"> </span><span class="lit">123</span><span class="pun">,</span><span class="pln">
    bam</span><span class="pun">:</span><span class="pln"> </span><span class="str">'abc'</span><span class="pun">,</span><span class="pln">   </span><span class="com">// complains about "bam" not being defined on Foo.</span><span class="pln">
  </span><span class="pun">};</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h3
          id="member-property-declarations"
          style="left: -46px; position: relative"
        >
          Member property declarations<a
            href="#member-property-declarations"
            alt="link to member-property-declarations"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Interface and class declarations must use the <code>;</code> character
          to separate individual member declarations:
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  memberA</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
  memberB</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          Interfaces specifically must not use the <code>,</code> character to
          separate fields, for symmetry with class declarations:
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  memberA</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">,</span><span class="pln">
  memberB</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">,</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>
          Inline object type declarations must use the comma as a separator:
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="pln">type </span><span class="typ">SomeTypeAlias</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  memberA</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">,</span><span class="pln">
  memberB</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">,</span><span class="pln">
</span><span class="pun">};</span><span class="pln">

</span><span class="kwd">let</span><span class="pln"> someProperty</span><span class="pun">:</span><span class="pln"> </span><span class="pun">{</span><span class="pln">memberC</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">,</span><span class="pln"> memberD</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">};</span></pre>

        <h4
          id="optimization-compatibility-for-property-access"
          style="left: -46px; position: relative"
        >
          Optimization compatibility for property access<a
            href="#optimization-compatibility-for-property-access"
            alt="link to optimization-compatibility-for-property-access"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Code must not mix quoted property access with dotted property access:
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Bad: code must use either non-quoted or quoted access for any property</span><span class="pln">
</span><span class="com">// consistently across the entire application:</span><span class="pln">
console</span><span class="pun">.</span><span class="pln">log</span><span class="pun">(</span><span class="pln">x</span><span class="pun">[</span><span class="str">'someField'</span><span class="pun">]);</span><span class="pln">
console</span><span class="pun">.</span><span class="pln">log</span><span class="pun">(</span><span class="pln">x</span><span class="pun">.</span><span class="pln">someField</span><span class="pun">);</span></pre>

        <p>
          Code must not rely on disabling renaming, but must rather declare all
          properties that are external to the application to prevent renaming:
        </p>

        <p>
          Prefer for code to account for a possible property-renaming
          optimization, and declare all properties that are external to the
          application to prevent renaming:
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Good: declaring an interface</span><span class="pln">
declare </span><span class="kwd">interface</span><span class="pln"> </span><span class="typ">ServerInfoJson</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  appVersion</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
  user</span><span class="pun">:</span><span class="pln"> </span><span class="typ">UserJson</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> data </span><span class="pun">=</span><span class="pln"> JSON</span><span class="pun">.</span><span class="pln">parse</span><span class="pun">(</span><span class="pln">serverResponse</span><span class="pun">)</span><span class="pln"> as </span><span class="typ">ServerInfoJson</span><span class="pun">;</span><span class="pln">
console</span><span class="pun">.</span><span class="pln">log</span><span class="pun">(</span><span class="pln">data</span><span class="pun">.</span><span class="pln">appVersion</span><span class="pun">);</span><span class="pln"> </span><span class="com">// Type safe &amp; renaming safe!</span></pre>

        <h4
          id="optimization-compatibility-for-module-object-imports"
          style="left: -46px; position: relative"
        >
          Optimization compatibility for module object imports<a
            href="#optimization-compatibility-for-module-object-imports"
            alt="link to optimization-compatibility-for-module-object-imports"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          When importing a module object, directly access properties on the
          module object rather than passing it around. This ensures that modules
          can be analyzed and optimized. Treating
          <a href="#module-versus-destructuring-imports">module imports</a> as
          namespaces is fine.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">import</span><span class="pln"> </span><span class="pun">{</span><span class="pln">method1</span><span class="pun">,</span><span class="pln"> method2</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'utils'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">class</span><span class="pln"> A </span><span class="pun">{</span><span class="pln">
  readonly utils </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">method1</span><span class="pun">,</span><span class="pln"> method2</span><span class="pun">};</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">import</span><span class="pln"> </span><span class="pun">*</span><span class="pln"> as utils </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'utils'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">class</span><span class="pln"> A </span><span class="pun">{</span><span class="pln">
  readonly utils </span><span class="pun">=</span><span class="pln"> utils</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h4 id="exception" style="left: -46px; position: relative">
          Exception<a href="#exception" alt="link to exception"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          This optimization compatibility rule applies to all web apps. It does
          not apply to code that only runs server side (e.g. in NodeJS for a
          test runner). It is still strongly encouraged to always declare all
          types and avoid mixing quoted and unquoted property access, for code
          hygiene.
        </p>

        <h3 id="enums" style="left: -46px; position: relative">
          Enums<a href="#enums" alt="link to enums"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Always use <code>enum</code> and not <code>const enum</code>.
          TypeScript enums already cannot be mutated; <code>const enum</code> is
          a separate language feature related to optimization that makes the
          enum invisible to JavaScript users of the module.
        </p>

        <h3 id="debugger-statements" style="left: -46px; position: relative">
          Debugger statements<a
            href="#debugger-statements"
            alt="link to debugger-statements"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>Debugger statements must not be included in production code.</p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">function</span><span class="pln"> debugMe</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">debugger</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h3 id="decorators" style="left: -46px; position: relative">
          Decorators<a href="#decorators" alt="link to decorators"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Decorators are syntax with an <code>@</code> prefix, like
          <code>@MyDecorator</code>.
        </p>

        <p>
          Do not define new decorators. Only use the decorators defined by
          frameworks:
        </p>

        <ul>
          <li>
            Angular (e.g. <code>@Component</code>, <code>@NgModule</code>, etc.)
          </li>
          <li>Polymer (e.g. <code>@property</code>)</li>
        </ul>

        <section>
          Why?

          <p>
            We generally want to avoid decorators, because they were an
            experimental feature that have since diverged from the TC39 proposal
            and have known bugs that won't be fixed.
          </p>
        </section>

        <p>
          When using decorators, the decorator must immediately precede the
          symbol it decorates, with no empty lines between:
        </p>

        <pre
          class="language-ts prettyprint prettyprinted"
          style=""
        ><span class="com">/** JSDoc comments go before decorators */</span><span class="pln">
</span><span class="lit">@Component</span><span class="pun">({...})</span><span class="pln">  </span><span class="com">// Note: no empty line after the decorator.</span><span class="pln">
</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">MyComp</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="lit">@Input</span><span class="pun">()</span><span class="pln"> myField</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">  </span><span class="com">// Decorators on fields may be on the same line...</span><span class="pln">

  </span><span class="lit">@Input</span><span class="pun">()</span><span class="pln">
  myOtherField</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">  </span><span class="com">// ... or wrap.</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h2 id="source-organization" style="left: -46px; position: relative">
          Source Organization<a
            href="#source-organization"
            alt="link to source-organization"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h2>

        <h3 id="modules" style="left: -46px; position: relative">
          Modules<a href="#modules" alt="link to modules"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <h4 id="import-paths" style="left: -46px; position: relative">
          Import Paths<a href="#import-paths" alt="link to import-paths"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          TypeScript code must use paths to import other TypeScript code. Paths
          may be relative, i.e. starting with <code>.</code> or <code>..</code>,
          or rooted at the base directory, e.g. <code>root/path/to/file</code>.
        </p>

        <p>
          Code <em>should</em> use relative imports (<code>./foo</code>) rather
          than absolute imports <code>path/to/foo</code> when referring to files
          within the same (logical) project.
        </p>

        <p>
          Consider limiting the number of parent steps (<code>../../../</code>)
          as those can make module and path structures hard to understand.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">import</span><span class="pln"> </span><span class="pun">{</span><span class="typ">Symbol1</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'google3/path/from/root'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="pun">{</span><span class="typ">Symbol2</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'../parent/file'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="pun">{</span><span class="typ">Symbol3</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./sibling'</span><span class="pun">;</span></pre>

        <h4 id="namespaces-vs-modules" style="left: -46px; position: relative">
          Namespaces vs Modules<a
            href="#namespaces-vs-modules"
            alt="link to namespaces-vs-modules"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          TypeScript supports two methods to organize code:
          <em>namespaces</em> and <em>modules</em>, but namespaces are
          disallowed. google3 code must use TypeScript <em>modules</em> (which
          are
          <a href="http://exploringjs.com/es6/ch_modules.html"
            >ECMAScript 6 modules</a
          >). That is, your code <em>must</em> refer to code in other files
          using imports and exports of the form
          <code>import {foo} from 'bar';</code>
        </p>

        <p>
          Your code must not use the
          <code>namespace Foo { ... }</code> construct. <code>namespace</code>s
          may only be used when required to interface with external, third party
          code. To semantically namespace your code, use separate files.
        </p>

        <p>
          Code must not use <code>require</code> (as in
          <code>import x = require('...');</code>) for imports. Use ES6 module
          syntax.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Bad: do not use namespaces:</span><span class="pln">
namespace </span><span class="typ">Rocket</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">function</span><span class="pln"> launch</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="com">// Bad: do not use &lt;reference&gt;</span><span class="pln">
</span><span class="com">/// &lt;reference path="..."/&gt;</span><span class="pln">

</span><span class="com">// Bad: do not use require()</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> x </span><span class="pun">=</span><span class="pln"> require</span><span class="pun">(</span><span class="str">'mydep'</span><span class="pun">);</span></pre>

        <blockquote>
          <p class="nb">
            NB: TypeScript <code>namespace</code>s used to be called internal
            modules and used to use the <code>module</code> keyword in the form
            <code>module Foo { ... }</code>. Don't use that either. Always use
            ES6 imports.
          </p>
        </blockquote>

        <h3 id="exports" style="left: -46px; position: relative">
          Exports<a href="#exports" alt="link to exports"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>Use named exports in all code:</p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Use named exports:</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">}</span></pre>

        <p>
          Do not use default exports. This ensures that all imports follow a
          uniform pattern.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Do not use default exports:</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">default</span><span class="pln"> </span><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">}</span><span class="pln"> </span><span class="com">// BAD!</span></pre>

        <section class="zippy">
          Why?

          <p>
            Default exports provide no canonical name, which makes central
            maintenance difficult with relatively little benefit to code owners,
            including potentially decreased readability:
          </p>

          <pre
            class="language-ts bad prettyprint prettyprinted"
            style=""
          ><span class="kwd">import</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./bar'</span><span class="pun">;</span><span class="pln">  </span><span class="com">// Legal.</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="typ">Bar</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./bar'</span><span class="pun">;</span><span class="pln">  </span><span class="com">// Also legal.</span></pre>

          <p>
            Named exports have the benefit of erroring when import statements
            try to import something that hasn't been declared. In
            <code>foo.ts</code>:
          </p>

          <pre
            class="language-ts bad prettyprint prettyprinted"
            style=""
          ><span class="kwd">const</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> </span><span class="str">'blah'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">default</span><span class="pln"> foo</span><span class="pun">;</span></pre>

          <p>And in <code>bar.ts</code>:</p>

          <pre
            class="language-ts bad prettyprint prettyprinted"
            style=""
          ><span class="kwd">import</span><span class="pln"> </span><span class="pun">{</span><span class="pln">fizz</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./foo'</span><span class="pun">;</span></pre>

          <p>
            Results in
            <code
              >error TS2614: Module '"./foo"' has no exported member
              'fizz'.</code
            >
            While <code>bar.ts</code>:
          </p>

          <pre
            class="language-ts bad prettyprint prettyprinted"
            style=""
          ><span class="kwd">import</span><span class="pln"> fizz </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./foo'</span><span class="pun">;</span></pre>

          <p>
            Results in <code>fizz === foo</code>, which is probably unexpected
            and difficult to debug.
          </p>

          <p>
            Additionally, default exports encourage people to put everything
            into one big object to namespace it all together:
          </p>

          <pre
            class="language-ts bad prettyprint prettyprinted"
            style=""
          ><span class="kwd">export</span><span class="pln"> </span><span class="kwd">default</span><span class="pln"> </span><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">static</span><span class="pln"> SOME_CONSTANT </span><span class="pun">=</span><span class="pln"> </span><span class="pun">...</span><span class="pln">
  </span><span class="kwd">static</span><span class="pln"> someHelpfulFunction</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">}</span><span class="pln">
  </span><span class="pun">...</span><span class="pln">
</span><span class="pun">}</span></pre>

          <p>
            With the above pattern, we have file scope, which can be used as a
            namespace. We also have a perhaps needless second scope (the class
            <code>Foo</code>) that can be ambiguously used as both a type and a
            value in other files.
          </p>

          <p>
            Instead, prefer use of file scope for namespacing, as well as named
            exports:
          </p>

          <pre
            class="language-ts good prettyprint prettyprinted"
            style=""
          ><span class="kwd">export</span><span class="pln"> </span><span class="kwd">const</span><span class="pln"> SOME_CONSTANT </span><span class="pun">=</span><span class="pln"> </span><span class="pun">...</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">function</span><span class="pln"> someHelpfulFunction</span><span class="pun">()</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">class</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// only class stuff here</span><span class="pln">
</span><span class="pun">}</span></pre>
        </section>

        <h4 id="export-visibility" style="left: -46px; position: relative">
          Export visibility<a
            href="#export-visibility"
            alt="link to export-visibility"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          TypeScript does not support restricting the visibility for exported
          symbols. Only export symbols that are used outside of the module.
          Generally minimize the exported API surface of modules.
        </p>

        <h4 id="mutable-exports" style="left: -46px; position: relative">
          Mutable Exports<a
            href="#mutable-exports"
            alt="link to mutable-exports"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Regardless of technical support, mutable exports can create hard to
          understand and debug code, in particular with re-exports across
          multiple modules. One way to paraphrase this style point is that
          <code>export let</code> is not allowed.
        </p>

        <section>
          <pre
            class="language-ts bad prettyprint prettyprinted"
            style=""
          ><span class="kwd">export</span><span class="pln"> </span><span class="kwd">let</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> </span><span class="lit">3</span><span class="pun">;</span><span class="pln">
</span><span class="com">// In pure ES6, foo is mutable and importers will observe the value change after a second.</span><span class="pln">
</span><span class="com">// In TS, if foo is re-exported by a second file, importers will not see the value change.</span><span class="pln">
window</span><span class="pun">.</span><span class="pln">setTimeout</span><span class="pun">(()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  foo </span><span class="pun">=</span><span class="pln"> </span><span class="lit">4</span><span class="pun">;</span><span class="pln">
</span><span class="pun">},</span><span class="pln"> </span><span class="lit">1000</span><span class="pln"> </span><span class="com">/* ms */</span><span class="pun">);</span></pre>
        </section>

        <p>
          If one needs to support externally accessible and mutable bindings,
          they should instead use explicit getter functions.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">let</span><span class="pln"> foo </span><span class="pun">=</span><span class="pln"> </span><span class="lit">3</span><span class="pun">;</span><span class="pln">
window</span><span class="pun">.</span><span class="pln">setTimeout</span><span class="pun">(()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  foo </span><span class="pun">=</span><span class="pln"> </span><span class="lit">4</span><span class="pun">;</span><span class="pln">
</span><span class="pun">},</span><span class="pln"> </span><span class="lit">1000</span><span class="pln"> </span><span class="com">/* ms */</span><span class="pun">);</span><span class="pln">
</span><span class="com">// Use an explicit getter to access the mutable export.</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">function</span><span class="pln"> getFoo</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="kwd">return</span><span class="pln"> foo</span><span class="pun">;</span><span class="pln"> </span><span class="pun">};</span></pre>

        <p>
          For the common pattern of conditionally exporting either of two
          values, first do the conditional check, then the export. Make sure
          that all exports are final after the module's body has executed.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">function</span><span class="pln"> pickApi</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">if</span><span class="pln"> </span><span class="pun">(</span><span class="pln">useOtherApi</span><span class="pun">())</span><span class="pln"> </span><span class="kwd">return</span><span class="pln"> </span><span class="typ">OtherApi</span><span class="pun">;</span><span class="pln">
  </span><span class="kwd">return</span><span class="pln"> </span><span class="typ">RegularApi</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">const</span><span class="pln"> </span><span class="typ">SomeApi</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> pickApi</span><span class="pun">();</span></pre>

        <h4 id="container-classes" style="left: -46px; position: relative">
          Container Classes<a
            href="#container-classes"
            alt="link to container-classes"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Do not create container classes with static methods or properties for
          the sake of namespacing.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">export</span><span class="pln"> </span><span class="kwd">class</span><span class="pln"> </span><span class="typ">Container</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="kwd">static</span><span class="pln"> FOO </span><span class="pun">=</span><span class="pln"> </span><span class="lit">1</span><span class="pun">;</span><span class="pln">
  </span><span class="kwd">static</span><span class="pln"> bar</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="kwd">return</span><span class="pln"> </span><span class="lit">1</span><span class="pun">;</span><span class="pln"> </span><span class="pun">}</span><span class="pln">
</span><span class="pun">}</span></pre>

        <p>Instead, export individual constants and functions:</p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">export</span><span class="pln"> </span><span class="kwd">const</span><span class="pln"> FOO </span><span class="pun">=</span><span class="pln"> </span><span class="lit">1</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="kwd">function</span><span class="pln"> bar</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="kwd">return</span><span class="pln"> </span><span class="lit">1</span><span class="pun">;</span><span class="pln"> </span><span class="pun">}</span></pre>

        <h3 id="imports" style="left: -46px; position: relative">
          Imports<a href="#imports" alt="link to imports"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          There are four variants of import statements in ES6 and TypeScript:
        </p>

        <section>
          <table>
            <thead>
              <tr>
                <th>Import type</th>
                <th>Example</th>
                <th>Use for</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>module</td>
                <td><code>import * as foo from '...';</code></td>
                <td>TypeScript imports</td>
              </tr>
              <tr>
                <td>destructuring</td>
                <td><code>import {SomeThing} from '...';</code></td>
                <td>TypeScript imports</td>
              </tr>
              <tr>
                <td>default</td>
                <td><code>import SomeThing from '...';</code></td>
                <td>Only for other external code that requires them</td>
              </tr>
              <tr>
                <td>side-effect</td>
                <td><code>import '...';</code></td>
                <td>
                  Only to import libraries for their side-effects on load (such
                  as custom elements)
                </td>
              </tr>
            </tbody>
          </table>

          <pre
            class="language-ts good prettyprint prettyprinted"
            style=""
          ><span class="com">// Good: choose between two options as appropriate (see below).</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="pun">*</span><span class="pln"> as ng </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'@angular/core'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="pun">{</span><span class="typ">Foo</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./foo'</span><span class="pun">;</span><span class="pln">

</span><span class="com">// Only when needed: default imports.</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="typ">Button</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'Button'</span><span class="pun">;</span><span class="pln">

</span><span class="com">// Sometimes needed to import libraries for their side effects:</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="str">'jasmine'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="str">'@polymer/paper-button'</span><span class="pun">;</span></pre>
        </section>

        <h4
          id="module-versus-destructuring-imports"
          style="left: -46px; position: relative"
        >
          Module versus destructuring imports<a
            href="#module-versus-destructuring-imports"
            alt="link to module-versus-destructuring-imports"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Both module and destructuring imports have advantages depending on the
          situation.
        </p>

        <p>
          Despite the <code>*</code>, a module import is not comparable to a
          <q>wildcard</q> import as seen in other languages. Instead, module
          imports give a name to the entire module and each symbol reference
          mentions the module, which can make code more readable and gives
          autocompletion on all symbols in a module. They also require less
          import churn (all symbols are available), fewer name collisions, and
          allow terser names in the module that's imported. Module imports are
          particularly useful when using many different symbols from large APIs.
        </p>

        <p>
          Destructuring imports give local names for each imported symbol. They
          allow terser code when using the imported symbol, which is
          particularly useful for very commonly used symbols, such as Jasmine's
          <code>describe</code> and <code>it</code>.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Bad: overlong import statement of needlessly namespaced names.</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="pun">{</span><span class="typ">TableViewItem</span><span class="pun">,</span><span class="pln"> </span><span class="typ">TableViewHeader</span><span class="pun">,</span><span class="pln"> </span><span class="typ">TableViewRow</span><span class="pun">,</span><span class="pln"> </span><span class="typ">TableViewModel</span><span class="pun">,</span><span class="pln">
  </span><span class="typ">TableViewRenderer</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./tableview'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">let</span><span class="pln"> item</span><span class="pun">:</span><span class="pln"> </span><span class="typ">TableViewItem</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">...;</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Better: use the module for namespacing.</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="pun">*</span><span class="pln"> as tableview </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./tableview'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">let</span><span class="pln"> item</span><span class="pun">:</span><span class="pln"> tableview</span><span class="pun">.</span><span class="typ">Item</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">...;</span></pre>

        <pre
          class="language-ts prettyprint prettyprinted"
          style=""
        ><span class="kwd">import</span><span class="pln"> </span><span class="pun">*</span><span class="pln"> as testing </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./testing'</span><span class="pun">;</span><span class="pln">

</span><span class="com">// All tests will use the same three functions repeatedly.</span><span class="pln">
</span><span class="com">// When importing only a few symbols that are used very frequently, also</span><span class="pln">
</span><span class="com">// consider importing the symbols directly (see below).</span><span class="pln">
testing</span><span class="pun">.</span><span class="pln">describe</span><span class="pun">(</span><span class="str">'foo'</span><span class="pun">,</span><span class="pln"> </span><span class="pun">()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  testing</span><span class="pun">.</span><span class="pln">it</span><span class="pun">(</span><span class="str">'bar'</span><span class="pun">,</span><span class="pln"> </span><span class="pun">()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    testing</span><span class="pun">.</span><span class="pln">expect</span><span class="pun">(...);</span><span class="pln">
    testing</span><span class="pun">.</span><span class="pln">expect</span><span class="pun">(...);</span><span class="pln">
  </span><span class="pun">});</span><span class="pln">
</span><span class="pun">});</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Better: give local names for these common functions.</span><span class="pln">
</span><span class="kwd">import</span><span class="pln"> </span><span class="pun">{</span><span class="pln">describe</span><span class="pun">,</span><span class="pln"> it</span><span class="pun">,</span><span class="pln"> expect</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./testing'</span><span class="pun">;</span><span class="pln">

describe</span><span class="pun">(</span><span class="str">'foo'</span><span class="pun">,</span><span class="pln"> </span><span class="pun">()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  it</span><span class="pun">(</span><span class="str">'bar'</span><span class="pun">,</span><span class="pln"> </span><span class="pun">()</span><span class="pln"> </span><span class="pun">=&gt;</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    expect</span><span class="pun">(...);</span><span class="pln">
    expect</span><span class="pun">(...);</span><span class="pln">
  </span><span class="pun">});</span><span class="pln">
</span><span class="pun">});</span><span class="pln">
</span><span class="pun">...</span></pre>

        <h4 id="renaming-imports" style="left: -46px; position: relative">
          Renaming imports<a
            href="#renaming-imports"
            alt="link to renaming-imports"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Code <em>should</em> fix name collisions by using a module import or
          renaming the exports themselves. Code <em>may</em> rename imports
          (<code>import {SomeThing as SomeOtherThing}</code>) if needed.
        </p>

        <p>Three examples where renaming can be helpful:</p>

        <ol>
          <li>
            If it's necessary to avoid collisions with other imported symbols.
          </li>
          <li>If the imported symbol name is generated.</li>
          <li>
            If importing symbols whose names are unclear by themselves, renaming
            can improve code clarity. For example, when using RxJS the
            <code>from</code> function might be more readable when renamed to
            <code>observableFrom</code>.
          </li>
        </ol>

        <h4 id="import-export-type" style="left: -46px; position: relative">
          Import &amp; export type<a
            href="#import-export-type"
            alt="link to import-export-type"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Do not use <code>import type ... from</code> or
          <code>export type ... from</code>.
        </p>

        <p class="note">
          Note: this does not apply to exporting type definitions, i.e.
          <code>export type Foo = ...;</code>.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">import</span><span class="pln"> type </span><span class="pun">{</span><span class="typ">Foo</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./foo'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> type </span><span class="pun">{</span><span class="typ">Bar</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./bar'</span><span class="pun">;</span></pre>

        <p>Instead, just use regular imports:</p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">import</span><span class="pln"> </span><span class="pun">{</span><span class="typ">Foo</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./foo'</span><span class="pun">;</span><span class="pln">
</span><span class="kwd">export</span><span class="pln"> </span><span class="pun">{</span><span class="typ">Bar</span><span class="pun">}</span><span class="pln"> </span><span class="kwd">from</span><span class="pln"> </span><span class="str">'./bar'</span><span class="pun">;</span></pre>

        <p>
          TypeScript tooling automatically distinguishes symbols used as types
          vs symbols used as values and only generates runtime loads for the
          latter.
        </p>

        <section class="zippy">
          Why?

          <p>
            TypeScript tooling automatically handles the distinction and does
            not insert runtime loads for type references. This gives a better
            developer UX: toggling back and forth between
            <code>import type</code> and <code>import</code> is bothersome. At
            the same time, <code>import type</code> gives no guarantees: your
            code might still have a hard dependency on some import through a
            different transitive path.
          </p>

          <p>
            If you need to force a runtime load for side effects, use
            <code>import '...';</code>. See
          </p>

          <p>
            <code>export type</code> might seem useful to avoid ever exporting a
            value symbol for an API. However it does not give guarantees either:
            downstream code might still import an API through a different path.
            A better way to split &amp; guarantee type vs value usages of an API
            is to actually split the symbols into e.g.
            <code>UserService</code> and <code>AjaxUserService</code>. This is
            less error prone and also better communicates intent.
          </p>
        </section>

        <h3 id="organize-by-feature" style="left: -46px; position: relative">
          Organize By Feature<a
            href="#organize-by-feature"
            alt="link to organize-by-feature"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Organize packages by feature, not by type. For example, an online shop
          <em>should</em> have packages named <code>products</code>,
          <code>checkout</code>, <code>backend</code>, not
          <del
            ><code>views</code>, <code>models</code>,
            <code>controllers</code></del
          >.
        </p>

        <h2 id="type-system" style="left: -46px; position: relative">
          Type System<a href="#type-system" alt="link to type-system"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h2>

        <h3 id="type-inference" style="left: -46px; position: relative">
          Type Inference<a href="#type-inference" alt="link to type-inference"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Code may rely on type inference as implemented by the TypeScript
          compiler for all type expressions (variables, fields, return types,
          etc). The google3 compiler flags reject code that does not have a type
          annotation and cannot be inferred, so all code is guaranteed to be
          typed (but might use the <code>any</code> type explicitly).
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> x </span><span class="pun">=</span><span class="pln"> </span><span class="lit">15</span><span class="pun">;</span><span class="pln">  </span><span class="com">// Type inferred.</span></pre>

        <p>
          Leave out type annotations for trivially inferred types: variables or
          parameters initialized to a <code>string</code>, <code>number</code>,
          <code>boolean</code>, <code>RegExp</code> literal or
          <code>new</code> expression.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> x</span><span class="pun">:</span><span class="pln"> boolean </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">true</span><span class="pun">;</span><span class="pln">  </span><span class="com">// Bad: 'boolean' here does not aid readability</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Bad: 'Set' is trivially inferred from the initialization</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> x</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Set</span><span class="pun">&lt;</span><span class="pln">string</span><span class="pun">&gt;</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Set</span><span class="pun">();</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> x </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">Set</span><span class="pun">&lt;</span><span class="pln">string</span><span class="pun">&gt;();</span></pre>

        <p>
          For more complex expressions, type annotations can help with
          readability of the program. Whether an annotation is required is
          decided by the code reviewer.
        </p>

        <h4 id="return-types" style="left: -46px; position: relative">
          Return types<a href="#return-types" alt="link to return-types"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Whether to include return type annotations for functions and methods
          is up to the code author. Reviewers <em>may</em> ask for annotations
          to clarify complex return types that are hard to understand. Projects
          <em>may</em> have a local policy to always require return types, but
          this is not a general TypeScript style requirement.
        </p>

        <p>
          There are two benefits to explicitly typing out the implicit return
          values of functions and methods:
        </p>

        <ul>
          <li>More precise documentation to benefit readers of the code.</li>
          <li>
            Surface potential type errors faster in the future if there are code
            changes that change the return type of the function.
          </li>
        </ul>

        <h3 id="null-vs-undefined" style="left: -46px; position: relative">
          Null vs Undefined<a
            href="#null-vs-undefined"
            alt="link to null-vs-undefined"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          TypeScript supports <code>null</code> and
          <code>undefined</code> types. Nullable types can be constructed as a
          union type (<code>string|null</code>); similarly with
          <code>undefined</code>. There is no special syntax for unions of
          <code>null</code> and <code>undefined</code>.
        </p>

        <p>
          TypeScript code can use either <code>undefined</code> or
          <code>null</code> to denote absence of a value, there is no general
          guidance to prefer one over the other. Many JavaScript APIs use
          <code>undefined</code> (e.g. <code>Map.get</code>), while many DOM and
          Google APIs use <code>null</code> (e.g.
          <code>Element.getAttribute</code>), so the appropriate absent value
          depends on the context.
        </p>

        <h4
          id="nullableundefined-type-aliases"
          style="left: -46px; position: relative"
        >
          Nullable/undefined type aliases<a
            href="#nullableundefined-type-aliases"
            alt="link to nullableundefined-type-aliases"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Type aliases <em>must not</em> include <code>|null</code> or
          <code>|undefined</code> in a union type. Nullable aliases typically
          indicate that null values are being passed around through too many
          layers of an application, and this clouds the source of the original
          issue that resulted in <code>null</code>. They also make it unclear
          when specific values on a class or interface might be absent.
        </p>

        <p>
          Instead, code <em>must</em> only add <code>|null</code> or
          <code>|undefined</code> when the alias is actually used. Code
          <em>should</em> deal with null values close to where they arise, using
          the above techniques.
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="com">// Bad</span><span class="pln">
type </span><span class="typ">CoffeeResponse</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="typ">Latte</span><span class="pun">|</span><span class="typ">Americano</span><span class="pun">|</span><span class="kwd">undefined</span><span class="pun">;</span><span class="pln">

</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">CoffeeService</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  getLatte</span><span class="pun">():</span><span class="pln"> </span><span class="typ">CoffeeResponse</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">};</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Better</span><span class="pln">
type </span><span class="typ">CoffeeResponse</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="typ">Latte</span><span class="pun">|</span><span class="typ">Americano</span><span class="pun">;</span><span class="pln">

</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">CoffeeService</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  getLatte</span><span class="pun">():</span><span class="pln"> </span><span class="typ">CoffeeResponse</span><span class="pun">|</span><span class="kwd">undefined</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">};</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Best</span><span class="pln">
type </span><span class="typ">CoffeeResponse</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="typ">Latte</span><span class="pun">|</span><span class="typ">Americano</span><span class="pun">;</span><span class="pln">

</span><span class="kwd">class</span><span class="pln"> </span><span class="typ">CoffeeService</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  getLatte</span><span class="pun">():</span><span class="pln"> </span><span class="typ">CoffeeResponse</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
    </span><span class="kwd">return</span><span class="pln"> assert</span><span class="pun">(</span><span class="pln">fetchResponse</span><span class="pun">(),</span><span class="pln"> </span><span class="str">'Coffee maker is broken, file a ticket'</span><span class="pun">);</span><span class="pln">
  </span><span class="pun">};</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h4
          id="optionals-vs-undefined-type"
          style="left: -46px; position: relative"
        >
          Optionals vs <code>|undefined</code> type<a
            href="#optionals-vs-undefined-type"
            alt="link to optionals-vs-undefined-type"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          In addition, TypeScript supports a special construct for optional
          parameters and fields, using <code>?</code>:
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">CoffeeOrder</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  sugarCubes</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">;</span><span class="pln">
  milk</span><span class="pun">?:</span><span class="pln"> </span><span class="typ">Whole</span><span class="pun">|</span><span class="typ">LowFat</span><span class="pun">|</span><span class="typ">HalfHalf</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">function</span><span class="pln"> pourCoffee</span><span class="pun">(</span><span class="pln">volume</span><span class="pun">?:</span><span class="pln"> </span><span class="typ">Milliliter</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="pun">...</span><span class="pln"> </span><span class="pun">}</span></pre>

        <p>
          Optional parameters implicitly include <code>|undefined</code> in
          their type. However, they are different in that they can be left out
          when constructing a value or calling a method. For example,
          <code>{sugarCubes: 1}</code> is a valid
          <code>CoffeeOrder</code> because <code>milk</code> is optional.
        </p>

        <p>
          Use optional fields (on interfaces or classes) and parameters rather
          than a <code>|undefined</code> type.
        </p>

        <p>
          For classes preferably avoid this pattern altogether and initialize as
          many fields as possible.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">class</span><span class="pln"> </span><span class="typ">MyClass</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  field </span><span class="pun">=</span><span class="pln"> </span><span class="str">''</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h3
          id="structural-types-vs-nominal-types"
          style="left: -46px; position: relative"
        >
          Structural Types vs Nominal Types<a
            href="#structural-types-vs-nominal-types"
            alt="link to structural-types-vs-nominal-types"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          TypeScript's type system is structural, not nominal. That is, a value
          matches a type if it has at least all the properties the type requires
          and the properties' types match, recursively.
        </p>

        <p>
          Use structural typing where appropriate in your code. Outside of test
          code, use interfaces to define structural types, not classes. In test
          code it can be useful to have mock implementations structurally match
          the code under test without introducing an extra interface.
        </p>

        <p>
          When providing a structural-based implementation, explicitly include
          the type at the declaration of the symbol (this allows more precise
          type checking and error reporting).
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> foo</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Foo</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  a</span><span class="pun">:</span><span class="pln"> </span><span class="lit">123</span><span class="pun">,</span><span class="pln">
  b</span><span class="pun">:</span><span class="pln"> </span><span class="str">'abc'</span><span class="pun">,</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> badFoo </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  a</span><span class="pun">:</span><span class="pln"> </span><span class="lit">123</span><span class="pun">,</span><span class="pln">
  b</span><span class="pun">:</span><span class="pln"> </span><span class="str">'abc'</span><span class="pun">,</span><span class="pln">
</span><span class="pun">}</span></pre>

        <section class="zippy">
          Why?

          <p>
            The <q>badFoo</q> object above relies on type inference. Additional
            fields could be added to <q>badFoo</q> and the type is inferred
            based on the object itself.
          </p>

          <p>
            When passing a <q>badFoo</q> to a function that takes a <q>Foo</q>,
            the error will be at the function call site, rather than at the
            object declaration site. This is also useful when changing the
            surface of an interface across broad codebases.
          </p>

          <pre
            class="language-ts prettyprint prettyprinted"
            style=""
          ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">Animal</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  sound</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
  name</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="kwd">function</span><span class="pln"> makeSound</span><span class="pun">(</span><span class="pln">animal</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Animal</span><span class="pun">)</span><span class="pln"> </span><span class="pun">{}</span><span class="pln">

</span><span class="com">/**
 * 'cat' has an inferred type of '{sound: string}'
 */</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> cat </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  sound</span><span class="pun">:</span><span class="pln"> </span><span class="str">'meow'</span><span class="pun">,</span><span class="pln">
</span><span class="pun">};</span><span class="pln">

</span><span class="com">/**
 * 'cat' does not meet the type contract required for the function, so the
 * TypeScript compiler errors here, which may be very far from where 'cat' is
 * defined.
 */</span><span class="pln">
makeSound</span><span class="pun">(</span><span class="pln">cat</span><span class="pun">);</span><span class="pln">

</span><span class="com">/**
 * Horse has a structural type and the type error shows here rather than the
 * function call.  'horse' does not meet the type contract of 'Animal'.
 */</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> horse</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Animal</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  sound</span><span class="pun">:</span><span class="pln"> </span><span class="str">'niegh'</span><span class="pun">,</span><span class="pln">
</span><span class="pun">};</span><span class="pln">

</span><span class="kwd">const</span><span class="pln"> dog</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Animal</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  sound</span><span class="pun">:</span><span class="pln"> </span><span class="str">'bark'</span><span class="pun">,</span><span class="pln">
  name</span><span class="pun">:</span><span class="pln"> </span><span class="str">'MrPickles'</span><span class="pun">,</span><span class="pln">
</span><span class="pun">};</span><span class="pln">

makeSound</span><span class="pun">(</span><span class="pln">dog</span><span class="pun">);</span><span class="pln">
makeSound</span><span class="pun">(</span><span class="pln">horse</span><span class="pun">);</span></pre>
        </section>

        <h3
          id="interfaces-vs-type-aliases"
          style="left: -46px; position: relative"
        >
          Interfaces vs Type Aliases<a
            href="#interfaces-vs-type-aliases"
            alt="link to interfaces-vs-type-aliases"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          TypeScript supports
          <a
            href="https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-aliases"
            >type aliases</a
          >
          for naming a type expression. This can be used to name primitives,
          unions, tuples, and any other types.
        </p>

        <p>
          However, when declaring types for objects, use interfaces instead of a
          type alias for the object literal expression.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">User</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  firstName</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
  lastName</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="pln">type </span><span class="typ">User</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  firstName</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">,</span><span class="pln">
  lastName</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">,</span><span class="pln">
</span><span class="pun">}</span></pre>

        <section class="zippy">
          Why?

          <p>
            These forms are nearly equivalent, so under the principle of just
            choosing one out of two forms to prevent variation, we should choose
            one. Additionally, there also
            <a href="https://ncjamieson.com/prefer-interfaces/"
              >interesting technical reasons to prefer interface</a
            >. That page quotes the TypeScript team lead:
            <q
              >Honestly, my take is that it should really just be interfaces for
              anything that they can model. There is no benefit to type aliases
              when there are so many issues around display/perf.</q
            >
          </p>
        </section>

        <h3 id="arrayt-type" style="left: -46px; position: relative">
          <code>Array&lt;T&gt;</code> Type<a
            href="#arrayt-type"
            alt="link to arrayt-type"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          For simple types (containing just alphanumeric characters and dot),
          use the syntax sugar for arrays, <code>T[]</code>, rather than the
          longer form <code>Array&lt;T&gt;</code>.
        </p>

        <p>
          For anything more complex, use the longer form
          <code>Array&lt;T&gt;</code>.
        </p>

        <p>
          This also applies for <code>readonly T[]</code> vs
          <code>ReadonlyArray&lt;T&gt;</code>.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> a</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">[];</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> b</span><span class="pun">:</span><span class="pln"> readonly string</span><span class="pun">[];</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> c</span><span class="pun">:</span><span class="pln"> ns</span><span class="pun">.</span><span class="typ">MyObj</span><span class="pun">[];</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> d</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Array</span><span class="pun">&lt;</span><span class="pln">string</span><span class="pun">|</span><span class="pln">number</span><span class="pun">&gt;;</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> e</span><span class="pun">:</span><span class="pln"> </span><span class="typ">ReadonlyArray</span><span class="pun">&lt;</span><span class="pln">string</span><span class="pun">|</span><span class="pln">number</span><span class="pun">&gt;;</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> f</span><span class="pun">:</span><span class="pln"> </span><span class="typ">Array</span><span class="pun">&lt;</span><span class="pln">string</span><span class="pun">&gt;;</span><span class="pln">            </span><span class="com">// the syntax sugar is shorter</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> g</span><span class="pun">:</span><span class="pln"> </span><span class="typ">ReadonlyArray</span><span class="pun">&lt;</span><span class="pln">string</span><span class="pun">&gt;;</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> h</span><span class="pun">:</span><span class="pln"> </span><span class="pun">{</span><span class="pln">n</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">,</span><span class="pln"> s</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">}[];</span><span class="pln"> </span><span class="com">// the braces/parens make it harder to read</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> i</span><span class="pun">:</span><span class="pln"> </span><span class="pun">(</span><span class="pln">string</span><span class="pun">|</span><span class="pln">number</span><span class="pun">)[];</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> j</span><span class="pun">:</span><span class="pln"> readonly </span><span class="pun">(</span><span class="pln">string</span><span class="pun">|</span><span class="pln">number</span><span class="pun">)[];</span></pre>

        <h3
          id="indexable-key-string-number-type"
          style="left: -46px; position: relative"
        >
          Indexable (<code>{[key: string]: number}</code>) Type<a
            href="#indexable-key-string-number-type"
            alt="link to indexable-key-string-number-type"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          In JavaScript, it's common to use an object as an associative array
          (aka
          <q>map</q>, <q>hash</q>, or <q>dict</q>):
        </p>

        <pre
          class="language-ts prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> fileSizes</span><span class="pun">:</span><span class="pln"> </span><span class="pun">{[</span><span class="pln">fileName</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">]:</span><span class="pln"> number</span><span class="pun">}</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">{};</span><span class="pln">
fileSizes</span><span class="pun">[</span><span class="str">'readme.txt'</span><span class="pun">]</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="lit">541</span><span class="pun">;</span></pre>

        <p>
          In TypeScript, provide a meaningful label for the key. (The label only
          exists for documentation; it's unused otherwise.)
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> users</span><span class="pun">:</span><span class="pln"> </span><span class="pun">{[</span><span class="pln">key</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">]:</span><span class="pln"> number</span><span class="pun">}</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">...;</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> users</span><span class="pun">:</span><span class="pln"> </span><span class="pun">{[</span><span class="pln">userName</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">]:</span><span class="pln"> number</span><span class="pun">}</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="pun">...;</span></pre>

        <blockquote>
          <p>
            Rather than using one of these, consider using the ES6
            <code>Map</code> and <code>Set</code> types instead. JavaScript
            objects have
            <a href="http://2ality.com/2012/01/objects-as-maps.html"
              >surprising undesirable behaviors</a
            >
            and the ES6 types more explicitly convey your intent. Also,
            <code>Map</code>s can be keyed by—and <code>Set</code>s can
            contain—types other than <code>string</code>.
          </p>
        </blockquote>

        <p>
          TypeScript's builtin <code>Record&lt;Keys, ValueType&gt;</code> type
          allows constructing types with a defined set of keys. This is distinct
          from associative arrays in that the keys are statically known. See
          advice on that <a href="#mapped-conditional-types">below</a>.
        </p>

        <h3
          id="mapped-conditional-types"
          style="left: -46px; position: relative"
        >
          Mapped &amp; Conditional Types<a
            href="#mapped-conditional-types"
            alt="link to mapped-conditional-types"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          TypeScript's
          <a
            href="https://www.typescriptlang.org/docs/handbook/advanced-types.html#mapped-types"
            >mapped types</a
          >
          and
          <a
            href="https://www.typescriptlang.org/docs/handbook/advanced-types.html#conditional-types"
            >conditional types</a
          >
          allow specifying new types based on other types. TypeScript's standard
          library includes several type operators based on these
          (<code>Record</code>, <code>Partial</code>,
          <code>Readonly</code> etc).
        </p>

        <p>
          These type system features allow succinctly specifying types and
          constructing powerful yet type safe abstractions. They come with a
          number of drawbacks though:
        </p>

        <ul>
          <li>
            Compared to explicitly specifying properties and type relations
            (e.g. using interfaces and extension, see below for an example),
            type operators require the reader to mentally evaluate the type
            expression. This can make programs substantially harder to read, in
            particular combined with type inference and expressions crossing
            file boundaries.
          </li>
          <li>
            Mapped &amp; conditional types' evaluation model, in particular when
            combined with type inference, is underspecified, not always well
            understood, and often subject to change in TypeScript compiler
            versions. Code can <q>accidentally</q> compile or seem to give the
            right results. This increases future support cost of code using type
            operators.
          </li>
          <li>
            Mapped &amp; conditional types are most powerful when deriving types
            from complex and/or inferred types. On the flip side, this is also
            when they are most prone to create hard to understand and maintain
            programs.
          </li>
          <li>
            Some language tooling does not work well with these type system
            features. E.g. your IDE's find references (and thus rename property
            refactoring) will not find properties in a
            <code>Pick&lt;T, Keys&gt;</code> type, and Code Search won't
            hyperlink them.
          </li>
          <li></li>
        </ul>

        <p>The style recommendation is:</p>

        <ul>
          <li>
            Always use the simplest type construct that can possibly express
            your code.
          </li>
          <li>
            A little bit of repetition or verbosity is often much cheaper than
            the long term cost of complex type expressions.
          </li>
          <li>
            Mapped &amp; conditional types may be used, subject to these
            considerations.
          </li>
        </ul>

        <section class="zippy">
          For example, TypeScript's builtin
          <code>Pick&lt;T, Keys&gt;</code> type allows creating a new type by
          subsetting another type <code>T</code>, but simple interface extension
          can often be easier to understand.

          <pre
            class="language-ts prettyprint prettyprinted"
            style=""
          ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">User</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  shoeSize</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">;</span><span class="pln">
  favoriteIcecream</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
  favoriteChocolate</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="com">// FoodPreferences has favoriteIcecream and favoriteChocolate, but not shoeSize.</span><span class="pln">
type </span><span class="typ">FoodPreferences</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> </span><span class="typ">Pick</span><span class="pun">&lt;</span><span class="typ">User</span><span class="pun">,</span><span class="pln"> </span><span class="str">'favoriteIcecream'</span><span class="pun">|</span><span class="str">'favoriteChocolate'</span><span class="pun">&gt;;</span></pre>

          <p>
            This is equivalent to spelling out the properties on
            <code>FoodPreferences</code>:
          </p>

          <pre
            class="language-ts prettyprint prettyprinted"
            style=""
          ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">FoodPreferences</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  favoriteIcecream</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
  favoriteChocolate</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span></pre>

          <p>
            To reduce duplication, <code>User</code> could extend
            <code>FoodPreferences</code>, or (possibly better) nest a field for
            food preferences:
          </p>

          <pre
            class="language-ts good prettyprint prettyprinted"
            style=""
          ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">FoodPreferences</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="com">/* as above */</span><span class="pln"> </span><span class="pun">}</span><span class="pln">
</span><span class="kwd">interface</span><span class="pln"> </span><span class="typ">User</span><span class="pln"> extends </span><span class="typ">FoodPreferences</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  shoeSize</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">;</span><span class="pln">
  </span><span class="com">// also includes the preferences.</span><span class="pln">
</span><span class="pun">}</span></pre>

          <p>
            Using interfaces here makes the grouping of properties explicit,
            improves IDE support, allows better optimization, and arguably makes
            the code easier to understand.
          </p>
        </section>

        <h3 id="any" style="left: -46px; position: relative">
          <code>any</code> Type<a href="#any" alt="link to any"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          TypeScript's <code>any</code> type is a super and subtype of all other
          types, and allows dereferencing all properties. As such,
          <code>any</code> is dangerous - it can mask severe programming errors,
          and its use undermines the value of having static types in the first
          place.
        </p>

        <section>
          <strong>Consider <em>not</em> to use <code>any</code>.</strong> In
          circumstances where you want to use <code>any</code>, consider one of:
        </section>

        <ul>
          <li><a href="#any-specific">Provide a more specific type</a></li>
          <li>
            <a href="#any-unknown">Use <code>unknown</code></a>
          </li>
          <li>
            <a href="#any-suppress"
              >Suppress the lint warning and document why</a
            >
          </li>
        </ul>

        <h4 id="any-specific" style="left: -46px; position: relative">
          Providing a more specific type<a
            href="#any-specific"
            alt="link to any-specific"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>Use interfaces , an inline object type, or a type alias:</p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Use declared interfaces to represent server-side JSON.</span><span class="pln">
declare </span><span class="kwd">interface</span><span class="pln"> </span><span class="typ">MyUserJson</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  name</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
  email</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="com">// Use type aliases for types that are repetitive to write.</span><span class="pln">
type </span><span class="typ">MyType</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> number</span><span class="pun">|</span><span class="pln">string</span><span class="pun">;</span><span class="pln">

</span><span class="com">// Or use inline object types for complex returns.</span><span class="pln">
</span><span class="kwd">function</span><span class="pln"> getTwoThings</span><span class="pun">():</span><span class="pln"> </span><span class="pun">{</span><span class="pln">something</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">,</span><span class="pln"> other</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">}</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// ...</span><span class="pln">
  </span><span class="kwd">return</span><span class="pln"> </span><span class="pun">{</span><span class="pln">something</span><span class="pun">,</span><span class="pln"> other</span><span class="pun">};</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="com">// Use a generic type, where otherwise a library would say `any` to represent</span><span class="pln">
</span><span class="com">// they don't care what type the user is operating on (but note "Return type</span><span class="pln">
</span><span class="com">// only generics" below).</span><span class="pln">
</span><span class="kwd">function</span><span class="pln"> nicestElement</span><span class="pun">&lt;</span><span class="pln">T</span><span class="pun">&gt;(</span><span class="pln">items</span><span class="pun">:</span><span class="pln"> T</span><span class="pun">[]):</span><span class="pln"> T </span><span class="pun">{</span><span class="pln">
  </span><span class="com">// Find the nicest element in items.</span><span class="pln">
  </span><span class="com">// Code can also put constraints on T, e.g. &lt;T extends HTMLElement&gt;.</span><span class="pln">
</span><span class="pun">}</span></pre>

        <h4 id="any-unknown" style="left: -46px; position: relative">
          Using <code>unknown</code> over <code>any</code
          ><a href="#any-unknown" alt="link to any-unknown"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          The <code>any</code> type allows assignment into any other type and
          dereferencing any property off it. Often this behaviour is not
          necessary or desirable, and code just needs to express that a type is
          unknown. Use the built-in type <code>unknown</code> in that situation
          — it expresses the concept and is much safer as it does not allow
          dereferencing arbitrary properties.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// Can assign any value (including null or undefined) into this but cannot</span><span class="pln">
</span><span class="com">// use it without narrowing the type or casting.</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> val</span><span class="pun">:</span><span class="pln"> unknown </span><span class="pun">=</span><span class="pln"> value</span><span class="pun">;</span></pre>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">const</span><span class="pln"> danger</span><span class="pun">:</span><span class="pln"> any </span><span class="pun">=</span><span class="pln"> value </span><span class="com">/* result of an arbitrary expression */</span><span class="pun">;</span><span class="pln">
danger</span><span class="pun">.</span><span class="pln">whoops</span><span class="pun">();</span><span class="pln">  </span><span class="com">// This access is completely unchecked!</span></pre>

        <section>
          To safely use <code>unknown</code> values, narrow the type using a
          <a
            href="https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types"
            >type guard</a
          >
        </section>

        <h4 id="any-suppress" style="left: -46px; position: relative">
          Suppressing <code>any</code> lint warnings<a
            href="#any-suppress"
            alt="link to any-suppress"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h4>

        <p>
          Sometimes using <code>any</code> is legitimate, for example in tests
          to construct a mock object. In such cases, add a comment that
          suppresses the lint warning, and document why it is legitimate.
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="com">// This test only needs a partial implementation of BookService, and if</span><span class="pln">
</span><span class="com">// we overlooked something the test will fail in an obvious way.</span><span class="pln">
</span><span class="com">// This is an intentionally unsafe partial mock</span><span class="pln">
</span><span class="com">// tslint:disable-next-line:no-any</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> mockBookService </span><span class="pun">=</span><span class="pln"> </span><span class="pun">({</span><span class="kwd">get</span><span class="pun">()</span><span class="pln"> </span><span class="pun">{</span><span class="pln"> </span><span class="kwd">return</span><span class="pln"> mockBook</span><span class="pun">;</span><span class="pln"> </span><span class="pun">}}</span><span class="pln"> as any</span><span class="pun">)</span><span class="pln"> as </span><span class="typ">BookService</span><span class="pun">;</span><span class="pln">
</span><span class="com">// Shopping cart is not used in this test</span><span class="pln">
</span><span class="com">// tslint:disable-next-line:no-any</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> component </span><span class="pun">=</span><span class="pln"> </span><span class="kwd">new</span><span class="pln"> </span><span class="typ">MyComponent</span><span class="pun">(</span><span class="pln">mockBookService</span><span class="pun">,</span><span class="pln"> </span><span class="com">/* unused ShoppingCart */</span><span class="pln"> </span><span class="kwd">null</span><span class="pln"> as any</span><span class="pun">);</span></pre>

        <h3 id="tuple-types" style="left: -46px; position: relative">
          Tuple Types<a href="#tuple-types" alt="link to tuple-types"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          If you are tempted to create a Pair type, instead use a tuple type:
        </p>

        <pre
          class="language-ts bad prettyprint prettyprinted"
          style=""
        ><span class="kwd">interface</span><span class="pln"> </span><span class="typ">Pair</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  first</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
  second</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">;</span><span class="pln">
</span><span class="pun">}</span><span class="pln">
</span><span class="kwd">function</span><span class="pln"> splitInHalf</span><span class="pun">(</span><span class="pln">input</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">):</span><span class="pln"> </span><span class="typ">Pair</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="pun">...</span><span class="pln">
  </span><span class="kwd">return</span><span class="pln"> </span><span class="pun">{</span><span class="pln">first</span><span class="pun">:</span><span class="pln"> x</span><span class="pun">,</span><span class="pln"> second</span><span class="pun">:</span><span class="pln"> y</span><span class="pun">};</span><span class="pln">
</span><span class="pun">}</span></pre>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">function</span><span class="pln"> splitInHalf</span><span class="pun">(</span><span class="pln">input</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">):</span><span class="pln"> </span><span class="pun">[</span><span class="pln">string</span><span class="pun">,</span><span class="pln"> string</span><span class="pun">]</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="pun">...</span><span class="pln">
  </span><span class="kwd">return</span><span class="pln"> </span><span class="pun">[</span><span class="pln">x</span><span class="pun">,</span><span class="pln"> y</span><span class="pun">];</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="com">// Use it like:</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> </span><span class="pun">[</span><span class="pln">leftHalf</span><span class="pun">,</span><span class="pln"> rightHalf</span><span class="pun">]</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> splitInHalf</span><span class="pun">(</span><span class="str">'my string'</span><span class="pun">);</span></pre>

        <p>
          However, often it's clearer to provide meaningful names for the
          properties.
        </p>

        <p>
          If declaring an <code>interface</code> is too heavyweight, you can use
          an inline object literal type:
        </p>

        <pre
          class="language-ts good prettyprint prettyprinted"
          style=""
        ><span class="kwd">function</span><span class="pln"> splitHostPort</span><span class="pun">(</span><span class="pln">address</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">):</span><span class="pln"> </span><span class="pun">{</span><span class="pln">host</span><span class="pun">:</span><span class="pln"> string</span><span class="pun">,</span><span class="pln"> port</span><span class="pun">:</span><span class="pln"> number</span><span class="pun">}</span><span class="pln"> </span><span class="pun">{</span><span class="pln">
  </span><span class="pun">...</span><span class="pln">
</span><span class="pun">}</span><span class="pln">

</span><span class="com">// Use it like:</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> address </span><span class="pun">=</span><span class="pln"> splitHostPort</span><span class="pun">(</span><span class="pln">userAddress</span><span class="pun">);</span><span class="pln">
use</span><span class="pun">(</span><span class="pln">address</span><span class="pun">.</span><span class="pln">port</span><span class="pun">);</span><span class="pln">

</span><span class="com">// You can also get tuple-like behavior using destructuring:</span><span class="pln">
</span><span class="kwd">const</span><span class="pln"> </span><span class="pun">{</span><span class="pln">host</span><span class="pun">,</span><span class="pln"> port</span><span class="pun">}</span><span class="pln"> </span><span class="pun">=</span><span class="pln"> splitHostPort</span><span class="pun">(</span><span class="pln">userAddress</span><span class="pun">);</span></pre>

        <h3 id="wrapper-types" style="left: -46px; position: relative">
          Wrapper types<a href="#wrapper-types" alt="link to wrapper-types"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          There are a few types related to JavaScript primitives that should
          never be used:
        </p>

        <ul>
          <li>
            <code>String</code>, <code>Boolean</code>, and
            <code>Number</code> have slightly different meaning from the
            corresponding primitive types <code>string</code>,
            <code>boolean</code>, and <code>number</code>. Always use the
            lowercase version.
          </li>
          <li>
            <code>Object</code> has similarities to both <code>{}</code> and
            <code>object</code>, but is slightly looser. Use <code>{}</code> for
            a type that include everything except <code>null</code> and
            <code>undefined</code>, or lowercase <code>object</code> to further
            exclude the other primitive types (the three mentioned above, plus
            <code>symbol</code> and <code>bigint</code>).
          </li>
        </ul>

        <p>
          Further, never invoke the wrapper types as constructors (with
          <code>new</code>).
        </p>

        <h3
          id="return-type-only-generics"
          style="left: -46px; position: relative"
        >
          Return type only generics<a
            href="#return-type-only-generics"
            alt="link to return-type-only-generics"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          Avoid creating APIs that have return type only generics. When working
          with existing APIs that have return type only generics always
          explicitly specify the generics.
        </p>

        <h2 id="consistency" style="left: -46px; position: relative">
          Consistency<a href="#consistency" alt="link to consistency"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h2>

        <p>
          For any style question that isn't settled definitively by this
          specification, do what the other code in the same file is already
          doing (<q>be consistent</q>). If that doesn't resolve the question,
          consider emulating the other files in the same directory.
        </p>

        <h3 id="goals" style="left: -46px; position: relative">
          Goals<a href="#goals" alt="link to goals"
            ><img
              src="../assets/img/link.png"
              width="21"
              height="21"
              style="float: left; position: relative; bottom: 5px"
          /></a>
        </h3>

        <p>
          In general, engineers usually know best about what's needed in their
          code, so if there are multiple options and the choice is situation
          dependent, we should let decisions be made locally. So the default
          answer should be <q>leave it out</q>.
        </p>

        <p>
          The following points are the exceptions, which are the reasons we have
          some global rules. Evaluate your style guide proposal against the
          following:
        </p>

        <ol>
          <li>
            <p>
              <strong
                >Code should avoid patterns that are known to cause problems,
                especially for users new to the language.</strong
              >
            </p>

            <p class="examples">Examples:</p>

            <ul>
              <li>
                The <code>any</code> type is easy to misuse (is that variable
                <em>really</em> both a number and callable as a function?), so
                we have recommendations for how to use it.
              </li>
              <li>
                TypeScript <code>namespace</code> causes trouble for Closure
                optimization.
              </li>
              <li>
                Periods within filenames make them ugly/confusing to import from
                JavaScript.
              </li>
              <li>
                Static functions in classes optimize confusingly, while often
                file-level functions accomplish the same goal.
              </li>
              <li>
                Users unaware of the <code>private</code> keyword will attempt
                to obfuscate their function names with underscores.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong
                >Code across projects should be consistent across irrelevant
                variations.</strong
              >
            </p>

            <p>
              When there are two options that are equivalent in a superficial
              way, we should consider choosing one just so we don't divergently
              evolve for no reason and avoid pointless debates in code reviews.
            </p>

            <p>
              We should usually match JavaScript style as well, because people
              often write both languages together.
            </p>

            <p class="examples">Examples:</p>

            <ul>
              <li>The capitalization style of names.</li>
              <li>
                <code>x as T</code> syntax vs the equivalent
                <code>&lt;T&gt;x</code> syntax (disallowed).
              </li>
              <li>
                <code>Array&lt;[number, number]&gt;</code> vs
                <code>[number, number][]</code>.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Code should be maintainable in the long term.</strong>
            </p>

            <p>
              Code usually lives longer than the original author works on it,
              and the TypeScript team must keep all of Google working into the
              future.
            </p>

            <p class="examples">Examples:</p>

            <ul>
              <li>
                We use software to automate changes to code, so code is
                autoformatted so it's easy for software to meet whitespace
                rules.
              </li>
              <li>
                We require a single set of Closure compilation flags, so a given
                TS library can be written assuming a specific set of flags, and
                users can always safely use a shared library.
              </li>
              <li>
                Code must import the libraries it uses (<q>strict deps</q>) so
                that a refactor in a dependency doesn't change the dependencies
                of its users.
              </li>
              <li>
                We ask users to write tests. Without tests we cannot have
                confidence that changes that we make to the language, or
                google3-wide library changes, don't break users.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong
                >Code reviewers should be focused on improving the quality of
                the code, not enforcing arbitrary rules.</strong
              >
            </p>

            <p>
              If it's possible to implement your rule as an automated check that
              is often a good sign. This also supports principle 3.
            </p>

            <p>
              If it really just doesn't matter that much -- if it's an obscure
              corner of the language or if it avoids a bug that is unlikely to
              occur -- it's probably worth leaving out.
            </p>
          </li>
        </ol>
        <div id="icons">
        <h4>Icons</h4>
        <div v-for="icon in icons" :key="icon">
          <label>.{{ icon }}</label> <br>
          <i class="glyphicons" :class="icon"></i> <br>
        </div>
      </div>
      </div>
      <app-footer />
    </div>

    <!-- Modal -->
    <div
      id="code-review-modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 id="exampleModalLabel" class="modal-title">Code review</h3>
          </div>
          <div class="modal-body">
            <div class="form-check">
              <input
                id="Syntax"
                class="form-check-input"
                type="checkbox"
                value=""
              />
              <label
                class="form-check-label"
                for="Syntax"
                style="margin-left: 5px"
              >
                <h5>Syntax</h5>
              </label>
            </div>
            <div class="form-check">
              <input
                id="LanguageRules"
                class="form-check-input"
                type="checkbox"
                value=""
              />
              <label
                class="form-check-label"
                for="LanguageRules"
                style="margin-left: 5px"
              >
                <h5>Language Rules</h5>
              </label>
            </div>
            <div class="form-check">
              <input
                id="Source"
                class="form-check-input"
                type="checkbox"
                value=""
              />
              <label
                class="form-check-label"
                for="Source"
                style="margin-left: 5px"
              >
                <h5>Source</h5>
              </label>
            </div>
            <div class="form-check">
              <input
                id="type"
                class="form-check-input"
                type="checkbox"
                value=""
              />
              <label
                class="form-check-label"
                for="type"
                style="margin-left: 5px"
              >
                <h5>Type system</h5>
              </label>
            </div>
            <div class="form-check">
              <input
                id="Consistency"
                class="form-check-input"
                type="checkbox"
                value=""
              />
              <label
                class="form-check-label"
                for="Consistency"
                style="margin-left: 5px"
              >
                <h5>Consistency</h5>
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="endReview"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import { allPossibleIcons } from '@/assets/AllPossibleIconNames.js'
export default {
  name: 'TerraIndexCodeConventions',
  components: {
    AppHeader,
    AppFooter
  },
  data () {
    return {
      popupVisible: false,
      icons: allPossibleIcons
    }
  },
  methods: {
    startCodeReview () {
      this.popupVisible = true
      document.getElementById('code-review-modal').style.display = 'block'
      document.getElementById('code-review-modal').classList.add('show')
      document.getElementById('code-review-modal').classList.add('in')
    },
    endReview () {
      document.getElementById('code-review-modal').style.display = ''
      document.getElementById('code-review-modal').classList.remove('show')
      document.getElementById('code-review-modal').classList.remove('in')
    }
  }
}
</script>

<style>
pre.bad {
  background-color: #eb5757;
  color: white;
}
</style>
