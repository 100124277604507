'use strict'
import translations from '@/configurations/app/config-locales'
import $ from 'jquery'

export default {
  popup (text) {
    // @TODO: Restore this to original styling.
    // this is included as raw javascript
    // eslint-disable-next-line no-undef
    sweetAlert(translations.t('label.warn'), text, 'warning')
  },
  acceptPopup (title, text, acceptCallback, cancelCallback) {
    return new Promise((resolve, reject) => {
      // this is included as raw javascript
      // eslint-disable-next-line no-undef
      swal({
        title: title,
        text: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: translations.t('button.confirm'),
        cancelButtonText: translations.t('button.cancel'),
        closeOnConfirm: true
      }, (isConfirm) => {
        if (isConfirm) {
          resolve()
        } else {
          reject(new Error('Not confirmed'))
        }
      })
    })
  },
  alertAsync (message, timeout, cssClass) {
    return new Promise((resolve, reject) => {
      const cls = '.' + (cssClass || 'alert')
      const alertEl = document.querySelector(cls)
      if (alertEl) {
        $(cls).html(message || '?')
        $(cls).slideDown()
        setTimeout(() => {
          $(cls).slideUp()
          resolve(true)
        }, timeout || 3000)
      } else {
        reject(new Error(`No element found with class set to ${cls}`))
      }
    })
  }
}
