<template>
  <footer>
    <div v-if="!(typeof $route.meta.buttons === 'undefined' || typeof $route.meta.buttons.left === 'undefined')" class="inner-footer-left">
      <footerbutton
        v-for="button in visibleLeftButtons"
        :id="button.name + '-button'"
        v-bind:key="button.name"
        :name="$t('label.' + button.name)"
        :class-name="button.class"
        :title="buttonTooltip('tooltips.' + button.name)"
        :container-class="shouldDisableButton(button) ? 'disabled' : ''"
        v-on:click.native="shouldDisableButton(button) ? null : buttonClick(button, button.handler)"
      >
      </footerbutton>
      <footerbutton
        v-if="shouldShowReportButton"
        id="report-button"
        :name="$t('label.report')"
        class-name="glyphicons glyphicons-book"
        :title="buttonTooltip('tooltips.report')"
        v-on:click.native="navigateToReports()"
      >
      </footerbutton>
    </div>
    <div v-if="!(typeof $route.meta.buttons === 'undefined' || typeof $route.meta.buttons.right === 'undefined')" class="inner-footer-right">
      <footerbutton
        v-for="button in visibleRightButtons"
        :id="button.name + '-button'"
        v-bind:key="button.name"
        :name="$t('label.' + button.name)"
        :container-class="shouldDisableButton(button) ? 'disabled' : 'button'"
        :class-name="button.class"
        :title="buttonTooltip('tooltips.' + button.name)"
        v-on:click.native="clickHandler(button)"
      >
      </footerbutton>
    </div>
  </footer>
</template>
<script>
import footerbutton from '@/components/_shared/footerbutton.vue'
import Popup from '@/utils/popup'
import config from '@/configurations/config.js'

let popup = Popup[config.platform].default
export default {
  name: 'Footer',
  props: ['selections', 'saveComponentDataFunction'],
  components: {
    footerbutton
  },
  data() {
    return {
      root: config.root
    }
  },
  computed: {
    shouldShowReportButton() {
      if (!this.shouldShowButton) {
        return false
      }

      if (['measurementPoint'].includes(this.$route.name)) {
        return false
      }

      return true
    },
    shouldExportReportButton() {
      if (!this.shouldShowButton) {
        return false
      }

      return true
    },
    shouldShowButton() {
      if (['projects', 'project', 'login'].includes(this.$route.name)) {
        return false
      }

      if (!this.$route.params.projectId) {
        return false
      }

      if (this.$route.params.projectId === '') {
        return false
      }

      return true
    },
    visibleRightButtons() {
      let result = {}
      if (!this.$route.meta.buttons.right) return result
      let p = this.$route.meta.buttons.right
      for (let key in p) {
        if (p.hasOwnProperty(key)) {
          if (p[key].visible) {
            result[key] = p[key]
          }
        }
      }
      return result
    },
    visibleLeftButtons() {
      let result = {}
      if (!this.$route.meta.buttons.left) return result
      let p = this.$route.meta.buttons.left
      for (let key in p) {
        if (p.hasOwnProperty(key)) {
          if (p[key].visible) {
            result[key] = p[key]
          }
        }
      }
      return result
    }
  },
  methods: {
    /**
     * Only return a value if we have a translation for a key
     */
    buttonTooltip(key) {
      const translation = this.$t(key)
      return translation === key ? '' : translation
    },
    shouldDisableButton(button) {
      return button.requiresSelection && button.requiresSelection !== this.selections.length
    },
    onSelectionChanged(selections) {
      this.selections = selections
    },
    buttonClick(sender, eventHandler) {
      let mainComponent = this.$route.matched[0]
      if (!mainComponent) {
        return popup.popup(this.$t('message.Unable_to_execute_action'))
      }

      let method = (mainComponent.instances.default || {})[eventHandler]
      method()
    },
    clickHandler(button) {
      if (!this.shouldDisableButton(button)) {
        this.buttonClick(button, button.handler)
      }
    },
    async navigateToExports() {
      if (this.$store.state.workingObjectChanged && this.saveComponentDataFunction && typeof this.saveComponentDataFunction === 'function') {
        await this.saveComponentDataFunction()
      }
      window.open(config.apps.export + this.$route.params.projectId, '_blank').focus()
    },
    async navigateToReports() {
      if (this.$store.state.workingObjectChanged && this.saveComponentDataFunction && typeof this.saveComponentDataFunction === 'function') {
        await this.saveComponentDataFunction()
      }
      window.open(config.apps.report + this.$route.params.projectId, '_blank').focus()
    }
  }
}
</script>
<style scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  height: 60px;
  background-color: #838383;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: whitesmoke;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.inner-footer-left {
  height: 30px;
  float: left;
}
.inner-footer-right {
  height: 30px;
  float: right;
}
@media (max-width: 415px) {
  .inner-footer-right {
    float: none;
  }
}
</style>
