<template>
    <figure :id="id" :class="containerClass || 'button'">
        <div class="footer-button">
            <span :class="className"></span>
        </div>
        <figcaption style="color:white;">{{name}}</figcaption>
    </figure>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    containerClass: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
  figure.button {
    text-align: center;
    cursor: pointer;
    display: inline-block !important;
    margin: 0 12px 0 0;
  }

  .footer-button {
    margin-bottom: -5px;
  }

  .footer-button span{
    font-size: 20px;
  }

  footer .disabled{
    margin: 0 12px 0 0;
  }

  .footer-button > span{
    width: 32px;
    height: 32px;
  }

  .footer-button > span.fa{
    font-size: 26px;
  }
</style>
